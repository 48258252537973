


export type ProductType = 'service' | 'article' | 'packaging' | 'formation' | 'maintenance' | 'contract' | 'fee';

export type PackagingProductType = 'service' | 'article';

export interface IProduct {
    id: number,
    isActive: boolean,
    name: string,
    description: string,

    sharingId: number,
    sharingName: string,

    priceExpression: string,
    maxDiscountExpression: string,
    maxLoadedExpression: string,
    lineOfBusinessCode: string,

    customerPointExpression: string,

    type: ProductType,

    serviceBeneficiaryPointExpression: string,

    serviceDurationInMinute: number,
    priceExpressionUnity: string,
    filterOption: string,
    articleWrapping: string,

    fileName: string,
    base64Picture: string,
    
    canSellWithoutStock: boolean,
    allowBillingDeliveryWithoutStock: boolean,
    reorderThreshold: number,

    quantityAvailable: number,
    quantityStock: number,
    quantityDelivery: number,
    quantityBilling: number,


    cumulativeDurationRate: number,
    durationBeforeServiceRenewalInDay: number,

    appointmentColor: string,

    maintenanceCategoryClass: string,
	  contractScopeClass: string,

    lineOfBusinessName: string,

    serviceTasks: IServiceTask[],
    packagingProducts: IPackagingProduct[],
    formationBillingTypes: IFormationBillingType[]
  }

export interface IServiceTask {
  id: number,
  isActive: boolean,

  serviceId: number,
  taskCode: string,
  part: number,

  servicePart: number
}

export interface IPackagingProduct {
  id: number,
  
  packagingId: number,
  part: number,
  

  type: PackagingProductType,

  serviceId: number,
  personServiceCode: string,
  serviceBeneficiaryPointExpression: string,

  articleId: number,

  description: string,
  productName: string
}

export interface IFormationBillingType {
  id: number,
  
  formationId: number,
  billingTypeCode: string,
  dueDate: Date,
  amountPart: number,

  billingTypeName: string
}

  
  export const defaultProduct : IProduct = {
    id: 0,
    isActive: true,
    name: '',
    description: '',

    sharingId: 0,
    sharingName: '',

    priceExpression: '',
    maxDiscountExpression: '',
    maxLoadedExpression: '',
    lineOfBusinessCode: '',

    customerPointExpression: '',

    type: 'service',

    serviceBeneficiaryPointExpression: '',

    serviceDurationInMinute: 0,
    priceExpressionUnity: '',
    filterOption: '',
    articleWrapping: '',

    fileName: '',
    base64Picture: '',
    
    canSellWithoutStock: false,
    allowBillingDeliveryWithoutStock: false,
    reorderThreshold: 0,

    quantityAvailable: 0,
    quantityStock: 0,
    quantityDelivery: 0,
    quantityBilling: 0,

    cumulativeDurationRate: 100,
    durationBeforeServiceRenewalInDay: 1,

    appointmentColor: '#305BE6',
    maintenanceCategoryClass: '',
	  contractScopeClass: '',

    lineOfBusinessName: '',

    serviceTasks: [],
    packagingProducts: [],
    formationBillingTypes: []
  }

  

  export interface IProductSearch {
 
    name: string,
    description: string,
    lineOfBusinessCode: string
  }