
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TokenIcon from '@mui/icons-material/Token';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ITemplateMessage, defaultTemplateMessage } from './models/TemplateMessage';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import useMessageService from './services/Message';


import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, 
  isSaveLoadingAtom, currentUserSessionAtom } from 'library/store';
import useTemplateMessageService, { useBasicFilterTemplateMessage } from './services/TemplateMessage';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

import useEnumerationService from 'features/configuration/services/Enumeration';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration, { Enum_CONTRACT_COUNTRY, Enum_CONTRACT_STATUS_PURPOSE, Enum_CONTRACT_STATUS,
   IEnumerationItem, 
   Enum_TEMPLATE_MESSAGE_STATUS_PURPOSE,
   Enum_TEMPLATE_MESSAGE_STATUS} from 'features/configuration/models/Enumeration';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';

import md5 from 'md5';
import { globalConfig } from 'config';
import { isFalsy } from 'utility-types';
import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { ThreeCircles } from 'react-loader-spinner';
import { green } from '@mui/material/colors';
import { SiMicrosoftexcel } from 'react-icons/si';

import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';
import { GrDocumentDownload } from 'react-icons/gr';


export const TemplateMessageForm: FC<ITemplateMessage> = (props: ITemplateMessage = {...defaultTemplateMessage} ) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();

  const theme = useTheme();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const { createTemplateMessage, updateTemplateMessage, retriveTemplateFile } = useTemplateMessageService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  
  const {language: lg, contractIds, userContracts , applicationSetup} = useRecoilValue(currentUserSessionAtom);

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const basicFilterTemplateMessage = useBasicFilterTemplateMessage( 
    (event: React.MouseEvent<unknown>, row: ITemplateMessage) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );


  const [isExportingMessages, setIsExportingMessages] = useState<boolean>(false);

  const emptyFunc = (obj: any) => {}

  const methods = useForm<ITemplateMessage>({defaultValues:{...defaultTemplateMessage}});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;
  
  const watchTemplateFileName = watch('templateFileName');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ITemplateMessage>,Error,ITemplateMessage>(
      _id>0?updateTemplateMessage:createTemplateMessage, {   
        onSuccess: (data: IResult<ITemplateMessage>) => {
          enqueueSnackbar( '_Operation_done', { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
                setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Template message')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['TemplateMessage',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<ITemplateMessage>(['TemplateMessage', _id], () => retrieveEntity('TemplateMessage',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'TemplateMessage'], () => getEnumerationItemsByEnumerationCodes
      ( [ Enum_TEMPLATE_MESSAGE_STATUS_PURPOSE, Enum_TEMPLATE_MESSAGE_STATUS ] ));

     const getInputAdornmentToken = (row: ITemplateMessage, cellId: keyof ITemplateMessage)  => ({
 
      toolTip: 'Token gen....',
      icon: TokenIcon,
      onClickIcon: (event: any, index: number, row: ITemplateMessage ) => {
        // const contract = getValues().contracts[index];    

        // (refUpdateTemplateMessage.current??emptyFunc)(index, {...contract, identityToken: generateToken(26)});
      }  
   })

      const cellEditable = (row: ITemplateMessage, cellId: keyof ITemplateMessage) => {
        return true;
     }
      
    
     function openFileDialog(  ) {
      (document as any).getElementById("file-upload").click();
      
    }

    const setFile = (_event: any) => {
      let f = _event.target.files![0];
      var reader = new FileReader();
      
      reader.onload = function () {

        var binaryString = reader.result as string;

        const base64String = binaryString
                                    .replace('data:', '')
                                    .replace(/^.+,/, '');

        setValue('templateFileName', f.name);
        setValue('base64TemplateFile', base64String);
        
      };

      reader.onerror = function () {
        console.log("File load failed");
      };
  
      reader.readAsDataURL(f);
    }

    const refAnchorElement = useRef<HTMLAnchorElement | null>(null);
    const handleDocumentDownload = async (event: any) => {
      const {id} = getValues();
      
      const {fileName, fileToken} = await retriveTemplateFile(id);      
      
      if(isFalsy(refAnchorElement.current)) return;

      refAnchorElement.current.href = `${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`;
      refAnchorElement?.current?.click();
    }

    
      
      useEffect( () => {        
        setCurrentFormNameAtom(t('Template message'));
        setCurrentBasicTextFilterProps(basicFilterTemplateMessage);

        // const {id} = currentUserSession;
        // _setId(id);
      }, []);    
      
      
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Template message')} - # ${_id} # -`: `${t('Template message')}` );
            if(_id > 0)
              retrieveData('TemplateMessage',_id, refetch);  
          }, [_id] );

              
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
          
            reset(_data);
        }
        }, [_data]);
    
           
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultTemplateMessage});    
      }

      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {  
        if(!checkEntitySaveAuthorization('TemplateMessage', _id)) {
          setIsSaveLoading(false);
          return;
        }
             
        
          const data = getValues(); 
          // if(data.fullName.trim() === '' ) {
          //     enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
          //           anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          //     return;
          //   }
                
          // data.contracts.forEach( ctr => {
          //     ctr.passwordMD5 = md5(ctr.password); 
          //     ctr.isPasswordChanged= (ctr.password != '********'); 
          //   } );
          
          mutate( {...data});
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('TemplateMessage', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                                                    
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                      
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                              <Controller 
                                name={`contractId`}
                                control={control}
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(36% - 8px)'}} id="status"
                                    label={t('Contract')} inputProps={ {readOnly: false}}>                                
                                    {userContracts.map( 
                                        (x,idx) => <MenuItem key={x.id} value={x.contractId}>{x.contractDescription}</MenuItem> )
                                        }
                                  </TextField>
                                )}
                              />
                              <TextField sx={{width:'calc(20% - 8px)'}} id="messagetHeader" label={t('Message header')} 
                                  {...register('messageHeader')} inputProps={ { maxLength: 11, autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } }
                                  helperText={`${t('Name view by recipient')} (11)`} />
                              <Controller control={control}
                                  name='effectiveDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Effective date')} 
                                      
                                      onChange={onChange}                        
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(22% - 8px)'}} />}
                                    /> )}
                                />
                                <Controller control={control}
                                  name='expirationDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Expiration date')} 
                                      
                                      onChange={onChange}                        
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(22% - 8px)'}} />}
                                    /> )}
                                />  
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} 
                                  {...register('description')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } } />                                
                          </Box>                                                    
                          
                          
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <Controller 
                                name={`status`}
                                control={control}
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="status"
                                    label={t('Status')} inputProps={ {readOnly: true}}>                                
                                    {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_TEMPLATE_MESSAGE_STATUS ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                  </TextField>
                                )}
                            /> 
                            <Controller control={control}
                                name='statusDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Date')} 
                                    onChange={onChange}  readOnly disableOpenPicker                   
                                    value={value}
                                    renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                                  /> )}
                              />                                
                             <Controller 
                                name={`status`}
                                control={control}
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                    label={t('Status purpose')} inputProps={ {readOnly: true}}>                                
                                    {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_TEMPLATE_MESSAGE_STATUS_PURPOSE).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                  </TextField>
                                )}
                            /> 
                          </Box>                          
                          <Box sx={{ mt: 1, width: '100%' }} >                                
                                <TextField sx={{width:'calc(100% - 8px)'}} id="messageBody" label={t('MessageBody')} multiline rows={5}
                                  {...register('messageBody')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                />
                          </Box>
                          <Box sx={{ mt: 1, width: '100%', pt: 3 }} >
                            <Stack flexDirection='row'>                              
                              <Box sx={{ mt: 1, width: '80%', pt: 0 }} >        
                                <Button onClick={ () => openFileDialog()} key={`lg - ${watchTemplateFileName} `} id={`lg - `}>
                                    <input type="file" id="file-upload" style={{ display: "none" }} onChange={ (_event) => setFile(_event) }/>
                                    {isFalsy(watchTemplateFileName) ? `${t('Click to toad file')} ...`: `${watchTemplateFileName} -> Click to change`}
                                </Button>                             
                              </Box>
                              <Box sx={{ ...justifyCenter, mt: 1, width: '5%', pt: 0 }} >
                                <Button>
                                  <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                    {`: :`}
                                  </Typography>
                                  <Box sx={{ ...justifyCenter, ml: 1 }} >                                                     
                                    <GrDocumentDownload size={24} onClick={handleDocumentDownload} />
                                  </Box>  
                                </Button>
                                <a href={`${globalConfig.get().apiUrl}/Download/${'token'}/${'name'}`} ref={refAnchorElement} id="fileDownload" className="hidden" target="__blank" download/>
                              </Box>
                            </Stack>    
                          </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={5} component={Paper} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={`${getValues().contractId}`} >
                          
                        </Box>           
                      </Stack>   
                    </Grid>                   
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

