
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IAppointment, IAppointmentSearch } from "../models/Appointment";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { addMonths, addDays } from 'date-fns';

import { currentUserSessionAtom } from 'library/store';
import { DurationInput, EventInput } from '@fullcalendar/core';
//import { IPolicyRisk, IPolicyRiskCoverage } from 'features/appointmention/models/Policy';


const _ = () => {

    const axios = useAxios(); 

    const createAppointment = async (appointment: IAppointment)  =>       
        await (await axios.post('/api/production/appointment/create', appointment)).data;       
        
    const updateAppointment = async (appointment: IAppointment)  =>       
        await (await axios.post('/api/production/appointment/update', appointment)).data; 
    
    const getAppointment = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/appointment/get-appointment/${id}`));
      return await data;
    }
   

    const getAppointments = async (criteria: IAppointmentSearch, pagination?: IPagination) : Promise<IAppointment[]> => {

      const {  startDate, endDate, name, portable, email} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/production/appointment/get-appointments?name=${name}&portable=${portable}&email=${email}&sStartDate=${sStartDate}&sEndDate=${sEndDate}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getTimeTable = async (date: Date, status: string, view: number) : Promise<
      {weekStart: Date, weekEnd: Date, slotMinTime: DurationInput, slotMaxTime: DurationInput, timetableEvents: EventInput[]}> => {

      const sDate = encodeURIComponent(date.toISOString().split('T')[0]);

      const {data} = (await axios.get(`/api/production/appointment/get-time-table?sDate=${sDate}&status=${status}&view=${view}`));
      return await data;
    }
    
   
    return {    
      createAppointment,
      updateAppointment,
      getAppointment,

      getAppointments,

      getTimeTable

      
    } 
}

export default _;

export interface IFilterAppointmentOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IAppointment[], React.Dispatch<React.SetStateAction<IAppointment[]>>],
}

const defaultFilterAppointmentOption: IFilterAppointmentOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterAppointment = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IAppointment) => void,
                                            filterOption?: IFilterAppointmentOption  ) => {

  const { getAppointments } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterAppointmentOption;



  const [headAppointmentCells, setHeadAppointmentCells]  = useState<HeadCell<IAppointment>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'firstName', label : t('First name'),  display: true, type: 'string', },
    {id:'lastName', label : t('Last name'),  display: true, type: 'string', },
    {id:'status', label : t('Status'),  display: true, type: 'string', },
    {id:'appointmentDate', label : t('Date'),  display: true, type: 'date', },

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
      {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
      {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},

      {name: 'name', text: t('Name'), value: ''},
      {name: 'portable', text: t('Portable'), value: ''},
      {name: 'email', text: t('Email'), value: ''},
    ]);    

  const [filteredAppointments, ] = useState<IAppointment[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IAppointment[]> => {
   
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();

    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const portable = filterElements.find( elt => elt.name === 'portable')?.value || '';
    const email = filterElements.find( elt => elt.name === 'email')?.value || '';

    const arr = await getAppointments( { startDate,endDate, name,portable,email}, pagination );
    
    return arr;
  }

  const objKey: keyof IAppointment = 'id';

  return {
    title: t('Appointment'), headCells: headAppointmentCells, objKey,
    filterElements, rows: filteredAppointments, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
