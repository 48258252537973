import { PersonType } from "features/production/models/Person";


export interface IAmount {type: string, amount: number, amountMonth: number}


export interface IBilling {
    id: number,

    customerId: number,
    businessEmployeeId: number,

    issueDate: Date,    
    reference: string,
   
    netAmount: number,
    tax: number,
    amount: number,    
    amountPaid: number, 
    unpaidAmount: number, 

    customerPersonType: PersonType,
    customerFirstName: string,
    customerLastName: string,
    customerBirthDateType: 'date' | 'day' | 'none',
    customerBirthDate: Date,
    customerParticularSign: string, 

    customerCorporationName: string,
    customerCorporationType: string,
    customerCreationDateType: 'date' | 'day' | 'none',
    customerCreationDate: Date,

    customerPortable1: string,
    customerPortable2: string,
    customerEmail1: string,
    customerEmail2: string, 

    status: string,
    statusDate: Date
    statusPurpose: string,

    businessEmployeeFullName: string,
    businessEmployeeManagerFullName: string,

    billingDetails: IBillingDetail[]
  }

  export interface IBillingDetail {
    id: number,
    billingId: number,

    baseAmount: number,
    discount: number,
    loaded: number,
    pricePurpose: string,
    netAmount: number,
    tax: number,
    amount: number,
    
    isDelivered: boolean,
    deliveryDate: Date,
    isDeliveryDateDefine: boolean,
  
    productId: number,
    
    contractDescription: string,
    contractEffectiveDate: Date,
    contractExpirationDate: Date,
    
    productName: string,
    lineOfBusinessCode: string,
    lineOfBusinessName: string,
    articleFilterOption: string,
    
  }

  
  export const defaultBilling : IBilling = {
    id: 0,
    customerId: 0,
    businessEmployeeId: 0,
    issueDate: new Date(),    
    reference: '',

    // isObligatory: false,
    // isCancelled: false,
    // cancellationPurpose: '',
    // cancellationDate: new Date(),   
    
       
    // billingTypeName: '',
    // billingCategoryName: '',

    netAmount: 0,
    tax: 0,
    amount: 0,    
    amountPaid: 0, 
    unpaidAmount: 0, 
    //dueAmount: 0,
    
    customerPersonType: 'physical',
    customerFirstName: '',
    customerLastName: '',
    customerBirthDateType: 'date',
    customerBirthDate: new Date(),
    customerParticularSign: '', 

    customerCorporationName: '',
    customerCorporationType: '',
    customerCreationDateType: 'date',
    customerCreationDate: new Date(),

    customerPortable1: '',
    customerPortable2: '',
    customerEmail1: '',
    customerEmail2: '', 


    status: '95',
    statusDate: new Date(),
    statusPurpose: '00',

    businessEmployeeFullName: '',
    businessEmployeeManagerFullName: '',

    billingDetails: []
  }

  export const defaultBillingDetail : IBillingDetail = {
    id: 0,
    billingId: 0,

    baseAmount: 0,
    discount: 0,
    loaded: 0,
    pricePurpose: '',
    netAmount: 0,
    tax: 0,
    amount: 0,

    isDelivered: true,
    deliveryDate: new Date(),
    isDeliveryDateDefine: true,

    productId: 0,
       
    productName: '',
    lineOfBusinessCode: '',
    lineOfBusinessName: '',
    articleFilterOption: '',
    
    contractDescription: '',
    contractEffectiveDate: new Date(),
    contractExpirationDate: new Date(),
    
  }

  

  export interface IBillingSearch {
    startDate: Date,
    endDate: Date,

    customerName: string,
    customerPortable: string,
    reference: string,
  }

 