// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Palette, PaletteColor, Typography } from '@mui/material';
// utils
//import { fShortenNumber } from '../../../utils/formatNumber';
// components
//import Iconify from '../../../components/iconify';


import { GrFormPreviousLink, GrFormNextLink } from 'react-icons/gr';

import numeral from 'numeral';

import { useRecoilValue } from 'recoil';

import {  currentUserSessionAtom } from 'library/store';


import  {iconFromName} from '../../components/ui/DynamicIcon';
import { isFalsy } from 'utility-types';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------


export interface WidgetSummaryProps {    
    title: string,
    total: number,
    total2?: number,
    iconName: string,    
    paletteColor: PaletteColor,
    
}

export default function WidgetSummary(prop: WidgetSummaryProps) {


  const {title, total, total2, paletteColor, iconName } = prop;

  const {language: lg} = useRecoilValue(currentUserSessionAtom);

  const sTotal = new Intl.NumberFormat('fr-FR').format(total);

  return (
    <Card
      sx={{
        py: 1,
        boxShadow: 0,
        textAlign: 'center',
        color: paletteColor.dark , // (theme) => theme.palette['primary'].dark, //.palette[color].darker,
        bgcolor: paletteColor.light,
        //...sx,
      }}
      //{...other}
    >
      <StyledIcon
        sx={{
          color: (theme) => paletteColor.dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(paletteColor.dark, 0)} 0%, ${alpha(
              paletteColor.dark,
              0.24
            )} 100%)`,
        }}
      >
        {iconFromName(iconName)}
        {/* <GrFormPreviousLink width={24} height={24} /> */}
      </StyledIcon> 

      <Typography variant="h4" sx={{ opacity: 0.99, fontWeight: 'bold' }}>{`${sTotal} ${isFalsy(total2)? '' : '/'} ${isFalsy(total2)? '' : numeral(total2).format('0 0')} `}</Typography>  
      {/* numeral(total2).format('0 0') */}

      <Typography variant="subtitle2" sx={{ opacity: 0.99, fontWeight: 'bold' }}>
        {title}
      </Typography>
    </Card>
  );
}