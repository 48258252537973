import React, { useEffect, useState, Suspense } from 'react';

import { useTheme } from '@mui/material/styles';

import './App.css';

import CssBaseline from '@mui/material/CssBaseline';
import Box  from '@mui/material/Box';
import Container from '@mui/material/Container/Container';

import {  useRecoilValue, useRecoilState } from 'recoil';

import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';

import { getUnixTime } from 'date-fns';



import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { SystemMenu } from 'components/SystemMenu';
import { AppRoute } from 'components/Route';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { EntityActionDrawer } from 'components/EntityActionDrawer';

import { currentUserSessionAtom, isActionDrawerOpenAtom, isAuthorizationBoxShowAtom, isDesktopPublishingBoxShowAtom, isExportBoxShowAtom, isPrintDrawerOpenAtom, isSearchBoxShowAtom } from 'library/store';
import { FilterBox } from 'components/FilterBox';
import { FilterFormDialog } from 'components/FilterFormDialog';
import { RequestNotification } from 'components/RequestNotification';
import { SessionTimeout } from 'components/SessionTimeout';
import { displayOnDesktop } from 'themes/commonStyles';
import { Typography } from '@mui/material';

import { QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { ExportFormDialog } from 'components/ExportFormDialog';
import { DesktopPublishingFormDialog } from 'components/DesktopPublishingFormDialog';
import { AuthorizationFormDialog } from 'components/AuthorizationFormDialog';
import { EntityPrintDrawer } from 'components/EntityPrintDrawer';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      //refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      //staleTime: twentyFourHoursInMs,
    },
  },
});

const getLocale = (lg: string) : Locale => {

  if(lg.includes('fr'))
    return frLocale;
  else if(lg.includes('en'))
    return enLocale;

  return frLocale;
}

function App() {

  const {isAuthenticated, language, applicationSetup } = useRecoilValue(currentUserSessionAtom);
  const [isActionDrawerOpen, setIsActionDrawerOpen] = useRecoilState(isActionDrawerOpenAtom);
  const [isPrintDrawerOpen, setIsPrintDrawerOpen] = useRecoilState(isPrintDrawerOpenAtom);
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);  
  
  const theme = useTheme();

  const [isAuthorizationBoxShow, setIsAuthorizationBoxShow] = useRecoilState(isAuthorizationBoxShowAtom);
  
  const [isExportBoxShow, setIsExportBoxShow] = useRecoilState(isExportBoxShowAtom); 
  const [isDesktopPublishingBoxShow, setIsDesktopPublishingBoxShow] = useRecoilState(isDesktopPublishingBoxShowAtom); 

  const [dateFnsLocale, setDateFnsLocale] = useState<Locale>(getLocale(language));

  useEffect( () =>{
    
    setDateFnsLocale(getLocale(language));
    
  }, [language] );

  const boxProps = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 100,
    overflowY: isAuthenticated?'scroll':'hidden',
    overflowX: 'hidden' 
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocale}>
        <CssBaseline />
        <RequestNotification />
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          { isAuthenticated && <Box>
            <Header />          
          </Box> }
          { isAuthenticated && <Box>
            <SystemMenu />          
          </Box> }
          {isSearchBoxShow && <FilterFormDialog /> } 
          {isExportBoxShow && <ExportFormDialog /> }
          {isDesktopPublishingBoxShow && <DesktopPublishingFormDialog /> }
          {isAuthorizationBoxShow && <AuthorizationFormDialog /> }
          <Box
            sx={ {...boxProps} }
          >
            <Container maxWidth="xl" disableGutters={true} sx={{ mb: 1 }}>
              <AppRoute />
              { /*isActionDrawerOpen &&*/ <EntityActionDrawer /> }
              { isPrintDrawerOpen && <EntityPrintDrawer /> }
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                <Typography sx={{ ml: 1, color: (theme) => theme.palette.secondary.main, fontSize: '20px', fontWeight: 'bold', }}
                  component="h3" >
                  Pulse - {applicationSetup.companyName}
                </Typography>
              </Box>
            </Container>
          </Box>

          { isAuthenticated && <Box sx={displayOnDesktop}>
            <Footer />
          </Box> }
          { isAuthenticated && <SessionTimeout {...{intialUnixTimeStamp: getUnixTime( (new Date())) }} />}
        </Box>
      </LocalizationProvider>
    </>
  );
}

export default App;
