
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import { IMessageDashboardDay, IMessage, IMessageSearch } from "../models/Message";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
import { start } from 'repl';
import { addMonths } from 'date-fns';


const _ = () => {

    const axios = useAxios(); 

    
    const createMessage = async (message: IMessage)  =>       
        await (await axios.post('/api/production/message/create', message)).data;       
        
    const updateMessage = async (message: IMessage)  =>       
        await (await axios.post('/api/production/message/update', message)).data; 
    
    const getMessage = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/message/get-message/${id}`));
      return await data;
    }

    const getMessages = async (criteria: IMessageSearch, pagination?: IPagination) : Promise<IMessage[]> => {

      const { messageBody, messageCampaign,recipientNumber ,startDate, endDate} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());
      
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;
      
      const {data} = (await axios.get(`/api/production/message/get-messages?messageCampaign=${messageCampaign}&recipientNumber=${recipientNumber}&` +
          `messageBody=${messageBody}&sStartDate=${sStartDate}&sEndDate=${sEndDate}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
      }
  
      const generateContractMessageXlsx = async (exportRequest: any) : Promise<{fileToken: string, fileName: string}> => {
           
        const {data} = (await axios.post(`/api/production/message/generate-contract-messages-xlsx`, exportRequest));
        return await data;
      }
  
      const getDayDashboard = async (dateFrom: Date, dateTo: Date) : Promise<IMessageDashboardDay> => {

        const sDateFrom = encodeURIComponent(dateFrom.toISOString().split('T')[0]);
        const sDateTo = encodeURIComponent(dateTo.toISOString().split('T')[0]);

        const {data} = (await axios.get(`/api/production/message/get-message-day-dashboard?sDateFrom=${sDateFrom}&sDateTo=${sDateTo}`));
        return await data;
      }

         
    return {    
      createMessage,
      updateMessage,
      
      getMessages,      
      generateContractMessageXlsx,
      getDayDashboard
      
    } 
}

export default _;

export const useBasicFilterMessage = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IMessage) => void ) => {

  const { t, i18n } = useTranslation();   
  const { getMessages } = _();

  const [headMessageCells, setHeadMessageCells]  = useState<HeadCell<IMessage>[]>([
    {id:'id', label : t('Id'),  display: false, type: 'numeric', width: 5 },
    {id:'messageCampaign', label : t('Type'),  display: true, type: 'string', width: 10},
    {id:'messageBody', label : t('MessageBody'),  display: true, type: 'string', width: 50},
   // {id:'fileName', label : t('File'),  display: true, type: 'string', width: 15},
    {id:'isSended', label : t('Is Sended ?'),  display: true, type: 'boolean', width: 15},
    
    {id:'sendingDate', label : t('Sending date'),  display: true, type: 'datetime', width: 15},
    {id:'recipientNumber', label : t('Recipient'),  display: true, type: 'string', width: 10},
    
    //{id:'validityEndDate', label : t('Expiration date'),  display: true, type: 'date', width: 10},
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
      
      {name: 'startDate', text: t('Start date'), dataType: 'date' , value: '', dateValue: addMonths(new Date(), - 1)},
      {name: 'endDate', text: t('End date'), dataType: 'date' , value: '', dateValue: new Date()},

      {name: 'messageCampaign', text: t('Type'), value: ''},
      {name: 'messageBody', text: t('MessageBody'), value: ''},
      {name: 'recipientNumber', text: t('Recipient'), value: ''},
      
    ]);    
  const [filteredMessages, ] = useState<IMessage[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IMessage[]> => {    
    
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();

    const recipientNumber = filterElements.find( elt => elt.name === 'recipientNumber')?.value || '';
    const messageBody = filterElements.find( elt => elt.name === 'messageBody')?.value || '';
    const messageCampaign = filterElements.find( elt => elt.name === 'messageCampaign')?.value || '';    
        
    const arr = await getMessages( {startDate, endDate, messageBody, messageCampaign, recipientNumber}, pagination );
    
    return arr.map(x => ({...x, /*statusText: x.status === 1? t('Delivered'): x.status === 2 ? t('Read') : t('Sended')*/ }) );
  }

  const objKey: keyof IMessage = 'id';

  return {
    title: t('Message'), headCells: headMessageCells, objKey,
    filterElements, rows: filteredMessages, 
    onFilterButtonClick, onRowDoubleClick
  }
}


