
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import { IDashboardSysBilling, ISysBilling, ISysBillingSearch } from "../models/SysBilling";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
import { start } from 'repl';
import { addMonths } from 'date-fns';


const _ = () => {

    const axios = useAxios(); 

    
    const createSysBilling = async (sysBilling: ISysBilling)  =>       
        await (await axios.post('/api/system/sysBilling/create', sysBilling)).data;       
        
    const updateSysBilling = async (sysBilling: ISysBilling)  =>       
        await (await axios.post('/api/system/sysBilling/update', sysBilling)).data; 
    
    const getSysBilling = async (id  : number )  => {
      const {data} = (await axios.get(`/api/system/sysBilling/get-sysBilling/${id}`));
      return await data;
    }

    const getSysBillings = async (criteria: ISysBillingSearch, pagination?: IPagination) : Promise<ISysBilling[]> => {

      const { startDate, endDate, reference} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());
      
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;
      
      const {data} = (await axios.get(`/api/system/sysBilling/get-sysBillings?reference=${reference}&` +
          `sStartDate=${sStartDate}&sEndDate=${sEndDate}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
      }

      const evaluateAmountSMS = async (data: {quantity: number}): Promise<IResult<{unityPrice: number, baseAmount: number, tax: number}>>  =>       
        await (await axios.post('/api/system/sysBilling/evaluate-sms-amount', data)).data; 

      const evaluateQuantitySMS = async (data: any): Promise<IResult<{unityPrice: number, baseAmount: number, tax: number}>>  =>       
        await (await axios.post('/api/system/sysBilling/evaluate-sms-quantity', data)).data; 

      const evaluateAmountService = async (data: any): Promise<IResult<{unityPrice: number, baseAmount: number, tax: number}>>  =>       
        await (await axios.post('/api/system/sysBilling/evaluate-service-amount', data)).data; 

      const evaluateAmountAppointment = async (data: any): Promise<IResult<{unityPrice: number, baseAmount: number, tax: number}>>  =>       
        await (await axios.post('/api/system/sysBilling/evaluate-appointment-amount', data)).data; 
  
      const generateContractSysBillingXlsx = async (exportRequest: any) : Promise<{fileToken: string, fileName: string}> => {
           
        const {data} = (await axios.post(`/api/system/sysBilling/generate-contract-sysBillings-xlsx`, exportRequest));
        return await data;
      }
  
      const getContractSysBillingsDashboard = async () : Promise<IDashboardSysBilling> => {
        const {data} = (await axios.get(`/api/system/sysBilling/get-sysBilling-dashboard`));
        return await data;
      }

         
    return {    
      createSysBilling,
      updateSysBilling,
      
      getSysBillings,      

      evaluateAmountSMS,
      evaluateQuantitySMS,
      evaluateAmountService,
      evaluateAmountAppointment,


      generateContractSysBillingXlsx,
      getContractSysBillingsDashboard
      
    } 
}

export default _;

export const useBasicFilterSysBilling = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ISysBilling) => void ) => {

  const { t, i18n } = useTranslation();   
  const { getSysBillings } = _();

  const [headSysBillingCells, setHeadSysBillingCells]  = useState<HeadCell<ISysBilling>[]>([
    {id:'id', label : t('Id'),  display: false, type: 'numeric', width: 5 },
        
    {id:'issueDate', label : t('Date'),  display: true, type: 'datetime', width: 15},
    {id:'reference', label : t('Recipient'),  display: true, type: 'string', width: 10},

    {id:'status', label : t('Status'),  display: true, type: 'string', width: 15},
    {id:'statusDate', label : t('Status date'),  display: true, type: 'date', width: 10},
    
    {id:'netAmount', label : t('Net amount'),  display: true, type: 'numeric', width: 10},
    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 10},
    {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 10},
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
      
      {name: 'startDate', text: t('Start date'), dataType: 'date' , value: '', dateValue: addMonths(new Date(), - 1)},
      {name: 'endDate', text: t('End date'), dataType: 'date' , value: '', dateValue: new Date()},

      {name: 'reference', text: t('Reference'), value: ''},
    ]);    
  const [filteredSysBillings, ] = useState<ISysBilling[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<ISysBilling[]> => {    
    
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();

    const reference = filterElements.find( elt => elt.name === 'reference')?.value || '';
            
    const arr = await getSysBillings( {startDate, endDate, reference}, pagination );
    
    return arr.map(x => ({...x, /*statusText: x.status === 1? t('Delivered'): x.status === 2 ? t('Read') : t('Sended')*/ }) );
  }

  const objKey: keyof ISysBilling = 'id';

  return {
    title: t('Billing'), headCells: headSysBillingCells, objKey,
    filterElements, rows: filteredSysBillings, 
    onFilterButtonClick, onRowDoubleClick
  }
}


