

export interface ITemplateMessage {
    id: number,
    
    contractId: number,

    description: string,

    messageHeader: string,

    messageBody: string,
    effectiveDate: Date,
    expirationDate: Date,
    
    status: string,
    statusDate: Date,
    statusPurpose: string,
              
    templateFileName: string,
    base64TemplateFile: string,
 
    
  }

  export const defaultTemplateMessage : ITemplateMessage = {
    id: 0,
    
    contractId: 0,

    description: '',

    messageHeader: '',

    messageBody: '',
    effectiveDate: new Date(),
    expirationDate: new Date(),

    status: '95',
    statusDate: new Date(),
    statusPurpose: '',
              
    templateFileName: '',
    base64TemplateFile: '',   
  }

  

  export interface ITemplateMessageSearch {
  
    messageHeader: string,
    messageBody: string,
    description: string,

    templateFileName: string
  }