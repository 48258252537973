import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { IResult } from 'library/interface';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


import { IApplicationQuery, IApplicationQueryParameter, IApplicationQuerySearch, IColumn, ITableFilter } from '../models/ApplicationQuery';


const _ = () => {

    const axios = useAxios();

    const createApplicationQuery = async (businessApplicationQuery: IApplicationQuery)  =>       
          await (await axios.post('/api/setup/applicationQuery/create', businessApplicationQuery)).data;       
        
       const updateApplicationQuery = async (businessApplicationQuery: IApplicationQuery)  =>       
          await (await axios.post('/api/setup/applicationQuery/update', businessApplicationQuery)).data;

    const getApplicationQueries = async (criteria: IApplicationQuerySearch) : Promise<IApplicationQuery[]> => {
        const {name, description} = criteria;

        const {data} = (await axios.get(`/api/setup/applicationQuery/get-applicationQueries?name=${name}&description=${description}`));
        return await data;
      }

      const getApplicationQueryParameters = async (applicationQueryId: number) : Promise<IApplicationQueryParameter[]> => {
         
          const {data} = (await axios.get(`/api/setup/applicationQuery/get-application-query-parameters?applicationQueryId=${applicationQueryId}`));
          return await data;
        }

        const generateApplicationQueryXlsx = async (applicationQueryExecution: any) : Promise<{fileToken: string, fileName: string}> => {
         
          const {data} = (await axios.post(`/api/setup/applicationQuery/generate-application-query-xlsx`, applicationQueryExecution));
          return await data;
        }

        const generateApplicationQueryPdf = async (applicationQueryExecution: any) : Promise<{fileToken: string, fileName: string}> => {
         
          const {data} = (await axios.post(`/api/setup/applicationQuery/generate-application-query-pdf`, applicationQueryExecution));
          return await data;
        }

        const executeApplicationQuery = async (applicationQueryExecution: any) : Promise<boolean> => {
         
          const {data} = (await axios.post(`/api/setup/applicationQuery/execute-application-query`, applicationQueryExecution));
          return await data;
        }

        const getTableFilters = async (name: string, description: string, pagination?: IPagination) : Promise<ITableFilter[]> => {
    
          const pageSize = pagination?.pageSize ?? 50;
          const pageNumber = pagination?.pageNumber ?? 1;
    
          const {data} = (await axios.get(`/api/setup/applicationQuery/get-tableFilters?name=${name}&description=${description}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
          return await data;
        }
        
        const getTableFilterColumns = async (tableName: string) : Promise<IColumn[]> => {
        
          const {data} = (await axios.get(`/api/setup/applicationQuery/get-tableFilter-columns?tableName=${tableName}`));
          return await data;
        }

    return {
        
        createApplicationQuery,
        updateApplicationQuery,

        
        getApplicationQueries,
        getApplicationQueryParameters,

        generateApplicationQueryXlsx,
        generateApplicationQueryPdf,
        
        executeApplicationQuery,

        getTableFilters,
        getTableFilterColumns
    } 
}

export default _;


  
export const useBasicFilterApplicationQuery = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IApplicationQuery) => void ) => {

const { t, i18n } = useTranslation();   
const { enqueueSnackbar } = useSnackbar();
const { getApplicationQueries } = _();

const [headApplicationQueryCells, ]  = useState<HeadCell<IApplicationQuery>[]>([
  {id:'id', label : t('Id'),  display: true, type: 'numeric', },
  {id:'name', label : t('Name'),  display: true, type: 'string', },
  {id:'description', label : t('Description'),  display: true, type: 'string', },
 

  ]);  
  const [filterElements,] = useState<ITextFilterElement[]>( [
    {name: 'name', text: t('Name'), value: ''},  
    {name: 'description', text: t('Description'), value: ''}, 
  ]);    
  const [filteredBusinessApplicationQueries, ] = useState<IApplicationQuery[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IApplicationQuery[]> => {

    
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';

    const arr = await getApplicationQueries( {name, description} );

    return arr;
  }

  const objKey: keyof IApplicationQuery = 'id';

  return {
    title: t('Queries'), headCells: headApplicationQueryCells, objKey,
    filterElements, rows: filteredBusinessApplicationQueries, 
    onFilterButtonClick, onRowDoubleClick
  }
}

export interface IFilterTableOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [ITableFilter[], React.Dispatch<React.SetStateAction<ITableFilter[]>>],
}

const defaultFilterTableOption: IFilterTableOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}

export const useBasicFilterTable = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ITableFilter) => void,
                                            filterOption?: IFilterTableOption  ) => {

  const { getTableFilters } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterTableOption;

  const [headTableFilterCells, setHeadTableFilterCells]  = useState<HeadCell<ITableFilter>[]>([
        
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      
    ]);    

  const [filteredProducts, ] = useState<ITableFilter[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<ITableFilter[]> => {
   
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    
    const arr = await getTableFilters(name,description, pagination );
    
    return arr;
  }

  const objKey: keyof ITableFilter = 'name';

  return {
    title: t('Table filter'), headCells: headTableFilterCells, objKey,
    filterElements, rows: filteredProducts, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}

