import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import {ITemplateMessage,  ITemplateMessageSearch  } from "../models/TemplateMessage";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';


const _ = () => {

    const axios = useAxios();     

    const createTemplateMessage = async (templateMessage: ITemplateMessage)  =>       
        await (await axios.post('/api/production/templateMessage/create', templateMessage)).data;       
        
    const updateTemplateMessage = async (templateMessage: ITemplateMessage)  =>       
        await (await axios.post('/api/production/templateMessage/update', templateMessage)).data; 
    
    const getTemplateMessage = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/templateMessage/get-templateMessage/${id}`));
      return await data;
    }

    const retriveTemplateFile = async (templateMessageIdId : number) : Promise<{fileToken: string, fileName: string}>  => {
      
      const {data} = (await axios.get(`/api/production/templateMessage/retrive-template-file?templateMessageIdId=${templateMessageIdId}`));
      return await data;
    }
    

    const getTemplateMessages = async (criteria: ITemplateMessageSearch , pagination?: IPagination) : Promise<ITemplateMessage[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {messageBody, description, templateFileName, messageHeader} = criteria;

      const {data} = (await axios.get(`/api/production/templateMessage/get-templateMessages?messageHeader=${messageHeader}&messageBody=${messageBody}&description=${description}&templateFileName=${templateFileName}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getTemplateMessagesByPage = async (pagination?: IPagination) : Promise<ITemplateMessage[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/production/templateMessage/get-templateMessages-by-page?pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    
      
    return {    
      createTemplateMessage,
      updateTemplateMessage,

      retriveTemplateFile,

      getTemplateMessage,
      getTemplateMessages,
      getTemplateMessagesByPage,

    } 
}

export default _;


export const useBasicFilterTemplateMessage = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ITemplateMessage) => void  ) => {

  const { getTemplateMessages } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  

  const [headTemplateMessageCells, setHeadTemplateMessageCells]  = useState<HeadCell<ITemplateMessage>[]>([
    
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'messageHeader', label : t('Message header'),  display: true, type: 'string', },
    {id:'templateFileName', label : t('File name'),  display: true, type: 'string', },
    {id:'messageBody', label : t('Message'),  display: true, type: 'string', },
  ]); 

  const [filterElements, ] = useState([       
        
        {name: 'description', text: t('Description'), value: ''}, 
        {name: 'messageHeader', text: t('Message header'), value: ''}, 
        {name: 'templateFileName', text: t('File name'), value: ''}, 
        {name: 'messageBody', text: t('Message'), value: ''}, 
      ]);

  const [filteredTemplateMessages, ] = useState<ITemplateMessage[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination): Promise<ITemplateMessage[]> => {
            
    const messageHeader = filterElements.find( elt => elt.name === 'messageHeader')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const templateFileName = filterElements.find( elt => elt.name === 'templateFileName')?.value || '';
    const messageBody = filterElements.find( elt => elt.name === 'messageBody')?.value || '';

    const arr = await getTemplateMessages( {messageHeader,description,templateFileName,messageBody}, pagination );
    
    return arr;
  }

  const objKey: keyof ITemplateMessage = 'id';

  return {
    title: 'TemplateMessage', headCells: headTemplateMessageCells, objKey,
    filterElements, rows: filteredTemplateMessages, 
    onFilterButtonClick, onRowDoubleClick
  }
}

