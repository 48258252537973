
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import {addDays} from 'date-fns';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';


import { globalConfig } from 'config';

import { IMessage, defaultMessage, } from './models/Message';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, currentUserSessionAtom, isSaveLoadingAtom } from 'library/store';
import useMessageService, { useBasicFilterMessage } from './services/Message';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Avatar, Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

import useEnumerationService from 'features/configuration/services/Enumeration';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration, { IEnumerationItem, Enum_MESSAGE_STATUS_PURPOSE, Enum_MESSAGE_STATUS} from 'features/configuration/models/Enumeration';
import { isFalsy } from 'utility-types';
import { typographyGroupBoxStyling } from 'themes/commonStyles';


export const MessageForm: FC<IMessage> = (props: IMessage = {...defaultMessage,
       effectiveDate:new Date(), expirationDate: addDays(new Date(), 1)}) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const { createMessage, updateMessage } = useMessageService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const {language: lg, contractIds, userContracts , roleEntities, applicationSetup} = useRecoilValue(currentUserSessionAtom);
    
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterMessage = useBasicFilterMessage( 
      (event: React.MouseEvent<unknown>, row: IMessage) => {
          
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const methods = useForm<IMessage>({defaultValues:{...defaultMessage,effectiveDate:new Date(), expirationDate: addDays(new Date(), 1)}});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchRecipientNumber = watch('recipientNumber');
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IMessage>,Error,IMessage>(
      _id>0?updateMessage:createMessage, {   
        onSuccess: (data: IResult<IMessage>) => {
          enqueueSnackbar( '_Operation_done', { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Message',data.data.id]);
          const btn = document.getElementById(`btnNew`);
          btn?.click();
        },
        onError: (err: Error) => {          
          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IMessage>(['Message', _id], () => retrieveEntity('Message',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'TemplateMessage'], () => getEnumerationItemsByEnumerationCodes
      ( [ Enum_MESSAGE_STATUS_PURPOSE, Enum_MESSAGE_STATUS ] ));

    const [anchorElContract, setAnchorElContract] = useState<null | HTMLElement>(null);
    const handleContractSwitch = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorElContract(event.currentTarget);
    };

    const handleContractChange = async (newContractId: number) => {              
      
      setValue('contractId',newContractId);        
      
      const userContract = userContracts.find(c => c.contractId === newContractId);
      if(!isFalsy(userContract)) {
        setCurrentContractDescription(userContract.contractDescription);
        
        
      }      
      
      setAnchorElContract(null);

    }

    const [currentContractId, setCurrentContractId] = useState<number>(0);
    const [currentContractDescription, setCurrentContractDescription] = useState<string>('');
        
    const [currentFileName, setCurrentFileName] = useState<string>('');
    const [currentBase64File, setCurrentBase64File] = useState<string>('');

    const [recipients, setRecipients] = useState<string[]>([]);


      function openFileDialog() {
        (document as any).getElementById("file-upload").click();
      }
    
      const setFile = (_event: any) => {
        let f = _event.target.files![0];
        var reader = new FileReader();
    
        reader.onload = function () {
    
            var binaryString = reader.result as string;
        
            const base64String = binaryString
                                        .replace('data:', '')
                                        .replace(/^.+,/, '');
    
            setCurrentBase64File(base64String);
            setCurrentFileName(f.name);
          };
      
          reader.onerror = function () {
            console.log("File load failed");
          };
      
          reader.readAsDataURL(f);    
      };

      useEffect(() => {
    
        const userContract = userContracts.find(c => c.isActive);
        if(!isFalsy(userContract)) {
          setCurrentContractDescription(userContract.contractDescription);
                    
          setValue('contractId',userContract.contractId);
        }
          
        
      }, []);

      useEffect( () => {        
        setCurrentFormNameAtom(t('Message'));
        setCurrentBasicTextFilterProps(basicFilterMessage);
      }, []);

      useEffect( () => {        
        setRecipients([...watchRecipientNumber.split(',')].filter(n => n.trim() !== '') );
      }, [watchRecipientNumber]);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Message',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {        
        _setId(0);  
        reset({...defaultMessage, effectiveDate:new Date(), expirationDate: addDays(new Date(), 1)  });         
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {    
        
        if(!checkEntitySaveAuthorization('Message', _id)){
          setIsSaveLoading(false);
          return;
        }

          const data = getValues();
          
          //const {id} = currentUserSession;
          if(data.recipientNumber.trim() === '' ) {
              enqueueSnackbar( t('There is no recipient'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              setIsSaveLoading(false);
              return;
            }

          if(data.messageBody.trim() === '') {
            enqueueSnackbar( t('Message body is not specified'), { variant: 'warning',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }
      
          mutate({...data});
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Message', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                              <Controller 
                              name={`contractId`}
                              control={control}
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(36% - 8px)'}} id="status"
                                  label={t('Contract')} inputProps={ {readOnly: false}}>                                
                                  {userContracts.map( 
                                      (x,idx) => <MenuItem key={x.id} value={x.contractId}>{x.contractDescription}</MenuItem> )
                                      }
                                </TextField>
                              )}
                              />
                              <TextField sx={{width:'calc(20% - 8px)'}} id="messagetHeader" label={t('Message header')} 
                                  {...register('messageHeader')} inputProps={ { maxLength: 11, autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } }
                                  helperText={`${t('Name view by recipient')} (11)`} />
                              <Controller control={control}
                                  name='effectiveDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Effective date')} 
                                      
                                      onChange={onChange}                        
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(22% - 8px)'}} />}
                                    /> )}
                                />
                                <Controller control={control}
                                  name='expirationDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Expiration date')} 
                                      
                                      onChange={onChange}                        
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(22% - 8px)'}} />}
                                    /> )}
                                />
                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >    
                              <TextField sx={{width:'calc(18% - 8px)'}} id="recipientNumber" label={t('Recipient')} 
                                  {...register('recipientNumber')} inputProps={ {readOnly: true}} />
                              <TextField sx={{width:'calc(18% - 8px)'}} id="groupRecipientId" label={t('Group')} 
                                  {...register('groupRecipientId')} inputProps={ {readOnly: true}} /> 
                              <TextField sx={{width:'calc(22% - 8px)'}} id="messageCampaign" label={t('Campaign')} 
                                  {...register('messageCampaign')} inputProps={ {readOnly: true}} /> 
                              <TextField sx={{width:'calc(42% - 8px)'}} id="messageReference" label={t('Reference')} 
                                  {...register('messageReference')} inputProps={ {readOnly: true}} />     
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >    
                              <TextField sx={{width:'calc(36% - 8px)'}} id="reportName" label={t('Report')} 
                                  {...register('reportName')} inputProps={ {readOnly: true}} />  
                              <TextField sx={{width:'calc(64% - 8px)'}} id="templateMessageDescription" label={t('Template message')} 
                                  {...register('templateMessageDescription')} inputProps={ {readOnly: true}} />    
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                            <Controller 
                                name={`status`}
                                control={control}
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="status"
                                    label={t('Status')} inputProps={ {readOnly: true}}>                                
                                    {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_MESSAGE_STATUS ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                  </TextField>
                                )}
                            /> 
                            <Controller control={control}
                                name='statusDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Date')} 
                                    onChange={onChange}  readOnly disableOpenPicker                   
                                    value={value}
                                    renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                                  /> )}
                              />                                
                             <Controller 
                                name={`status`}
                                control={control}
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                    label={t('Status purpose')} inputProps={ {readOnly: true}}>                                
                                    {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_MESSAGE_STATUS_PURPOSE).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                  </TextField>
                                )}
                            /> 
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >  
                            <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                  label={t('Is Sended ?')}
                                  control={
                                  <Controller
                                      name='isSended'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} disabled />}                        
                                />} />                              
                            <Controller control={control}
                              name='sendingDate' 
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DateTimePicker label={t('Sending date')} 
                                  
                                  onChange={onChange}                        
                                  value={value}
                                  renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                /> )}
                            />
                            <Controller name={`deliveryStatus`} control={control}                                    
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="status"
                                    label={t('Delivery status')} inputProps={ {readOnly: true}} > 
                                      <MenuItem value={0}>{t('Sended')}</MenuItem>                                           
                                      <MenuItem value={1}>{t('Delivered')}</MenuItem>         
                                      <MenuItem value={2}>{t('Read')}</MenuItem>                                                                                
                                  </TextField>
                                )}
                              />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat    
                                        label={`${t('Count sms')}`} sx={{width:'calc(25% - 8px)'}} disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name={`countCredit`}
                                  control={control}
                              />                           
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >    
                            <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat    
                                        label={`${t('Count failures')}`} sx={{width:'calc(25% - 8px)'}} disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name={`countConsecutiveFailures`}
                                  control={control}
                            />                            
                            <TextField sx={{width:'calc(75% - 8px)'}} id="lastErrorMessage" label={t('Last error message')}
                              {...register('lastErrorMessage')} 
                              inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: true } }
                            />   
                          </Box>                            
                          <Box sx={{ mt: 1, width: '100%' }} >                                
                              <TextField sx={{width:'calc(100% - 8px)'}} id="messageBody" label={t('MessageBody')} multiline rows={5}
                                {...register('messageBody')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              />   
                          </Box>
                        </Stack>                        
                    </Grid>  
                    <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          <Box sx={{ mt: 1, width: '100%' }} >                                
                              <TextField sx={{width:'calc(80% - 8px)'}} id="messageBody" label={t('Add number by pressing ENTER')} 
                                {...register('currentRecipient')} multiline maxRows={1}
                                InputProps={{
                                  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                                                                       
                                    if(event.key === 'Enter') {
                                      const {currentRecipient, recipientNumbers} = getValues();
                                      console.log({currentRecipient, recipientNumbers});

                                      const setNumber = new Set( currentRecipient.split('\n').filter(n => 
                                          n.trim() !== '' && !recipients.includes(n.trim()) ) );
                                      const currentRecipients = Array.from(setNumber);

                                      if(currentRecipients.length > 0) {
                                        setValue('recipientNumbers', [...recipientNumbers,...currentRecipients], {shouldValidate: true} ); //`${recipientNumber},${currentRecipients.join(',')}`.replace(/^,+/, '') 
                                        setValue('recipientNumber', currentRecipients[0]);
                                      }                                        

                                      setValue('currentRecipient', '');                                     
                                    }
                                  },
                                }}                                
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              />   
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} key={getValues().recipientNumbers.join('-')}>        
                            {                              
                              getValues().recipientNumbers.map( (num,idx) => 
                                (!isFalsy(num) && <Chip sx={{ ml: 1, mr:1, mb: 1 }}
                                    key={`${idx} - ${num}`} label={num} color="primary" variant="outlined"
                                    onDelete={() => {setValue('recipientNumbers', getValues().recipientNumbers.filter(recpt => recpt !== num) )}} />) )
                            }
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} key={recipients.length}>
                            <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {` ${getValues().recipientNumbers.length} ${t(('Recipient'))}(s) `}
                            </Typography> 
                          </Box>
                        </Stack>                  
                    </Grid>
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

