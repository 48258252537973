
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import { addMonths, addDays } from 'date-fns';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IBilling, IBillingDetail, IBillingSearch, } from "../models/Billing";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { isNumber, toNumber } from 'lodash';

import { currentUserSessionAtom } from 'library/store';

const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createBilling = async (billing: IBilling)  =>       
        await (await axios.post('/api/finance/billing/create', billing)).data;       
        
    const updateBilling = async (billing: IBilling)  =>       
        await (await axios.post('/api/finance/billing/update', billing)).data; 
    
    const getBilling = async (id  : number )  => {
      const {data} = (await axios.get(`/api/finance/billing/get-billing/${id}`));
      return await data;
    }

    
    const deliverBillingDetail = async (data: {id: number, deliveryDate: Date})  =>       
        await (await axios.post('/api/finance/billing/deliver-billing-detail', data)).data;
        

    const getBillings = async (criteria: IBillingSearch, pagination?: IPagination) : Promise<IBilling[]> => {
      
      const {startDate, endDate, customerName, customerPortable, reference} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/finance/billing/get-billings?customerName=${customerName}` +
        `&customerPortable=${customerPortable}&reference=${reference}&sStartDate=${sStartDate}&sEndDate=${sEndDate}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    

    const evaluateAddedProduct = async (billinAddedProduct: any): Promise<IResult<IBillingDetail>>  =>       
        await (await axios.post('/api/finance/billing/evaluate-added-product', billinAddedProduct)).data; 


    const getBillingsByRegistration = async (registrationId: number) : Promise<IBilling[]> => {
            
      const {data} = (await axios.get(`/api/finance/billing/get-billings-by-registration?registrationId=${registrationId}`));
      return await data;
    }

    
    const getLastServicesByPerson = async (personId: number) : Promise<{packagingName: string, serviceName: string, issueDate: Date}[]> => {
      
      const {data} = (await axios.get(`/api/finance/billing/get-last-billings-by-person?personId=${personId}`));
      return await data;
    }

          
    
    return {    
      createBilling,
      updateBilling,

      deliverBillingDetail,

      getBilling,
      getBillings,


      evaluateAddedProduct,
      
      getBillingsByRegistration,

      
      getLastServicesByPerson,
      
    } 
}

export default _;

export interface IFilterBillingOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IBilling[], React.Dispatch<React.SetStateAction<IBilling[]>>],
}

const defaultFilterProductOption: IFilterBillingOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterBilling = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IBilling) => void,
                                            filterOption?: IFilterBillingOption  ) => {

  const { getBillings } = _();

  const { t, i18n } = useTranslation();   

  const {language: lg, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  //const {schoolYears} = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;

  const [headBillingCells, setHeadBillingCells]  = useState<HeadCell<IBilling>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
   
    {id:lg.includes('en')?'customerFirstName':'customerLastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string'},
    {id:lg.includes('en')?'customerLastName':'customerFirstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string'},

    {id:'customerCorporationName', label : t('Name'),  display: true, type: 'string', },
    {id:'customerPortable1', label : t('Portable'),  display: true, type: 'string', },

    {id:'reference', label : t('Reference'),  display: true, type: 'string', },
    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', },

    {id: 'status', label: t('Status'), display: true, type: 'expression', 
      getExpressionNode: (row: IBilling, cellId: keyof IBilling) => {
          switch(row.status) {
            case '95': return t('Not validated');
            case '85': return t('Not validated cancelled');
            case '10': return t('Validated');
            case '80': return t('Cancelled');
            default: return '....';
          } }},    
    {id:'issueDate', label : t('Date'),  display: true, type: 'date', },
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
    {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
    {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},

    {name: 'customerName', text: t('Customer'), value: ''}, 
    {name: 'customerPortable', text: t('Portable'), value: ''}, 

    {name: 'reference', text: t('Reference'), value: ''},     
      
    ]);    

  const [filteredBillings, ] = useState<IBilling[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IBilling[]> => {
    
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();

    const customerName = filterElements.find( elt => elt.name === 'customerName')?.value || '';
    const customerPortable = filterElements.find( elt => elt.name === 'customerPortable')?.value || '';
   
    const reference = filterElements.find( elt => elt.name === 'reference')?.value || '';
          
    const arr = await getBillings( {startDate, endDate,customerName,customerPortable, reference}, pagination );
    
    return arr;
  }

  const objKey: keyof IBilling = 'id';

  return {
    title: t('Customer billing'), headCells: headBillingCells, objKey,
    filterElements, rows: filteredBillings, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}



