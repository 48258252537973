
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useEmployeeService, { useBasicFilterEmployee } from './services/Employee';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, Popover } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IEmployee, defaultEmployee, IEmployeeTask, IEmployeeFinancialSituation, defaultEmployeeFinancialSituation } from './models/Employee';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, Enum_REGISTRATION_STATUS, Enum_STUDY_TYPE, Enum_SERVICE_TASK,
  Enum_DEFAULT_LANGUAGE, Enum_TOWN, Enum_COUNTRY, Enum_PERSON_IDENTIFICATION, Enum_EMPLOYEE_TYPE,Enum_EMPLOYEE_CATEGORY,
    Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_EMPLOYEE_STATUS, Enum_EMPLOYEE_DIPLOMA, Enum_EMPLOYEE_SPECIALIZATION, Enum_EMPLOYEE_FUNCTION, Enum_EMPLOYEE_SERVICE } from 'features/configuration/models/Enumeration';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';


import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';

//import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';

import { DatePicker } from '@mui/x-date-pickers';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';

import { IPerson } from 'features/production/models/Person';
import { useBasicFilterPerson } from 'features/production/services/Person';

import ExtensionValueField from 'components/ui/ExtensionValueField';
import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';

import { ChromePicker, ColorResult } from 'react-color';


export const EmployeeForm: FC<IEmployee> = (props: IEmployee = defaultEmployee) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  const theme = useTheme();

  const {language: lg} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  const { createEmployee, updateEmployee, getEmployeeFinancialSituation } = useEmployeeService();

  //const { getSchoolYears, getSchoolYearClasses } = useSchoolYearService();


  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const refSchoolYears = useRef<ISchoolYear[]>();

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterEmployee = useBasicFilterEmployee( 
    (event: React.MouseEvent<unknown>, row: IEmployee) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
  const basicFilterPerson = useBasicFilterPerson( 
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, firstName, lastName, birthDate, birthPlace, particularSign} = row;
        
        setValue('personId', id);
        setValue('firstName', firstName);
        setValue('lastName', lastName);  
        setValue('birthDate', birthDate);   
        setValue('birthPlace', birthPlace);                    
        setValue('particularSign', particularSign);  
                          
        setOpenPersonFilter(false);
      }
  );

  const [openManagerEmployeeFilter, setOpenManagerEmployeeFilter] = useState(false);
  const basicFilterManagerEmployee = useBasicFilterEmployee( 
      (event: React.MouseEvent<unknown>, row: IEmployee) => {
        const {id, firstName, lastName, employeeTasks} = row;
        
        setValue('managerEmployeeId', id);
        setValue('managerEmployeeFullName', `${lg.includes('fr')? lastName:firstName} ${lg.includes('fr')? firstName:lastName}`.trim()); 

        setOpenManagerEmployeeFilter(false);
      }
  );

    
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  
  const emptyFunc = (obj: any) => {}
  
  const methods = useForm<IEmployee>({defaultValues:defaultEmployee});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchIsTechnician = watch('isTechnician');    
  const watchManagerEmployeeId = watch('managerEmployeeId');
  const watchBase64Picture = watch('base64Picture');
  const watchHasBonusOnTaskExecuted = watch('hasBonusOnTaskExecuted');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IEmployee>,Error,IEmployee>(
      _id>0?updateEmployee:createEmployee, {   
        onSuccess: (data: IResult<IEmployee>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Employee',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IEmployee>(['Employee', _id], () => retrieveEntity('Employee',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    
    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Employee'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_EMPLOYEE_STATUS, Enum_EMPLOYEE_DIPLOMA, Enum_EMPLOYEE_SPECIALIZATION, Enum_EMPLOYEE_SERVICE, Enum_EMPLOYEE_FUNCTION,
          Enum_EMPLOYEE_TYPE, Enum_DEFAULT_LANGUAGE, Enum_COUNTRY, Enum_PERSON_IDENTIFICATION, Enum_SERVICE_TASK, Enum_EMPLOYEE_CATEGORY,
              Enum_PERSON_GENDER, Enum_PERSON_CIVILITY ] ));

    //const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );


    const handleClickOpenPerson = async (event: any) => {
      setOpenPersonFilter(true);
    }
        
  
    function openFileDialog() {
      (document as any).getElementById("file-upload").click();
    }
  
    const setFile = (_event: any) => {
      let f = _event.target.files![0];
      var reader = new FileReader();
  
      reader.onload = function () {
  
          var binaryString = reader.result as string;
      
          const base64String = binaryString
                                      .replace('data:', '')
                                      .replace(/^.+,/, '');
  
          setValue("base64Picture", base64String);
          
        };
    
        reader.onerror = function () {
          console.log("File load failed");
        };
    
        reader.readAsDataURL(f);    
    };

    const getIEmployeeTaskCode = (row: IEmployeeTask, cellId: keyof IEmployeeTask, 
      opts: {value: string, name: string}[]) => {        
      
        return getAsOptions(refEnumItems.current ?? [],Enum_SERVICE_TASK);
    }
    const cellEditableIEmployeeTask = (row: IEmployeeTask, cellId: keyof IEmployeeTask) => {
      return true;
    }

    const [headEmployeeTaskCells, setHeadEmployeeTaskCells]  = useState<HeadCell<IEmployeeTask>[]>([      
      //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
      {id:'taskCode', label : t('Task'),  display: true, type: 'string', width: 70, isEditable: cellEditableIEmployeeTask,
        getOptions: getIEmployeeTaskCode   },
      {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', width: 30, isEditable: cellEditableIEmployeeTask },       
      
    ]);

    const refAppendEmployeeTasks = useRef<(value: Partial<FieldArray<IEmployee>> | Partial<FieldArray<IEmployee>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateEmployeeTask = useRef<(index: number,value: Partial<FieldArray<IEmployee>> ) => void>(null);
    const refRemoveEmployeeTask = useRef<(index: number ) => void>(null);
      
    const handleAddEmployeeTasks = (event: any) => {
      (refAppendEmployeeTasks.current??emptyFunc)({id: 0, employeeId: _id, taskCode: '', isActive: true});
    }

    const employeeTaskRowActionIcon = ( tarificationMatrix: IEmployeeTask) : ActionIconTableRow<IEmployee,IEmployeeTask> => {
  
      const res: ActionIconTableRow<IEmployee,IEmployeeTask> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IEmployeeTask) => {
          
           (refRemoveEmployeeTask.current??emptyFunc)(index);            
        }
      }
      return res;
  }

  const handleClickOpenManagerEmployee = (event: any) => {
    setOpenManagerEmployeeFilter(true);
  }

  const handleClickRemoveManagerEmployee = (event: any) => {
    setValue('managerEmployeeId', 0);
    setValue('managerEmployeeFullName', '');
  }

  const [openEntityExpression, setOpenEntityExpression] = useState(false);
  const [currentExpression, setCurrentExpression] = useState(''); 
  const [currentEntityName, setCurrentEntityName] = useState(''); 
  const [currentReturnType, setCurrentReturnType] = useState(''); 

  type ExpressionType = 'bonus';
  const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('bonus');

  const handleClickOpenExpression = (event: any, expressionType: ExpressionType, 
      entityName: string, returnType: string, expression: string) => {

    setCurrentExpressionType(expressionType);
    setCurrentEntityName(entityName);
    setCurrentReturnType(returnType);
    setCurrentExpression(expression);
    setOpenEntityExpression(true);
  }

  const handleClickOkExpression = async () => {

    const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);
    if(!checkExpression){
      enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
      return;
    }

    const {syntaxOk, syntaxError, returnType} = checkExpression;
    if(!syntaxOk) {
      enqueueSnackbar( syntaxError , { variant: 'error',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
      return;
    }
   
    if(returnType !== currentReturnType) {
      enqueueSnackbar( `${t('The result of expression must be')} ${currentReturnType}` , { variant: 'error',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        return;
    }

    const bonusAmountExpression = currentExpressionType === 'bonus' ? currentExpression: getValues().bonusAmountExpression;
    setValue('bonusAmountExpression', bonusAmountExpression);

    setOpenEntityExpression(false);
  }

  const refEnumItems = useRef<IEnumerationItem[]>();    
    useEffect( () => {   
        refEnumItems.current = enumItems;
      
    }, [enumItems])
    
      useEffect( () => {        
        setCurrentFormNameAtom(t('Employee'));
        setCurrentBasicTextFilterProps(basicFilterEmployee);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
      useEffect( () => {
          // setCurrentFormName(t('Billing'));        
          
          if(_id > 0)
            retrieveData('Employee',_id, refetch);  
        }, [_id] );


        const [financialSituation, setFinancialSituation] = useState<IEmployeeFinancialSituation>(defaultEmployeeFinancialSituation)
        useEffect( () => {
          
          async function _() {    
                 
            const fs = _id>0?  await getEmployeeFinancialSituation(_id ) : defaultEmployeeFinancialSituation;                    
            setFinancialSituation(fs);  
          }
          _();  
        }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultEmployee);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
        if(!checkEntitySaveAuthorization('Employee', _id)) {
          setIsSaveLoading(false);
             return;
        }
                
        const data = getValues(); 
        if(data.firstName.trim() === '' && data.lastName.trim() === '') {
            enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }

        // if(data.category.trim() === '') {
        //   enqueueSnackbar( t('Category is required'), { variant: 'warning',
        //     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        //   setIsSaveLoading(false);
        //   return;
        // }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Employee', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={8} lg={8} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 0.5, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} />
                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Regis. Number')} {...register('registrationNumber')} />
                                <Controller control={control}
                                  name='hiringDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Hiring date')} 
                                      onChange={onChange}                     
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                    /> )}
                                /> 
                                <Controller name='status' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="status"
                                        label={t('Status')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_EMPLOYEE_STATUS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller control={control}
                                  name='statusDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Status date')} 
                                      onChange={onChange}                     
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                    /> )}
                                /> 
                                <Controller name='serviceCode' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="serviceCode"
                                        label={t('Service')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_EMPLOYEE_SERVICE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />                                                             
                                <Controller name='employeeType' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="employeeType"
                                        label={t('Empl. type')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_EMPLOYEE_TYPE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                            </Box>   
                            <Box sx={{ mt: 1, width: '100%' }} key={`box person ${getValues().managerEmployeeId}`}>
                              <Controller name='lastDiploma' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="lastDiploma"
                                        label={t('Last diploma')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_EMPLOYEE_DIPLOMA ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller control={control}
                                  name='diplomaDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Diploma date')} 
                                      onChange={onChange}                     
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                    /> )}
                                />
                                <Controller name='category' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="category"
                                        label={t('Category')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_EMPLOYEE_CATEGORY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <TextField sx={{width:'calc(35% - 8px)'}} id="id" label={`${t('Manager')} - ${t('Employee')}`} 
                                    {...register('managerEmployeeFullName')} 
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">  
                                        { (isFalsy(watchManagerEmployeeId) ||  watchManagerEmployeeId<=0) ?
                                            <IconButton color="primary" onClick={handleClickOpenManagerEmployee}>
                                              <ArrowDropDownCircleIcon />
                                            </IconButton> : 
                                            <IconButton color="primary" onClick={handleClickRemoveManagerEmployee}>
                                              <RemoveCircleIcon />
                                            </IconButton>
                                          }                                                                                                                                     
                                      </InputAdornment>
                                    ) 
                                  }}/>
                                { openManagerEmployeeFilter && <FormDialog open={openManagerEmployeeFilter} maxWidth='md'
                                okText='' cancelText='' title={t('Employee')} onCancel={()=> {}} 
                                onClose={()=> {setOpenManagerEmployeeFilter(false);}} onOk={()=> {setOpenManagerEmployeeFilter(false);}}  >
                                    <BasicTextFilterForm<IEmployee> {...basicFilterManagerEmployee } />
                          </FormDialog> }
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Identity'))} `}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(15% - 8px)'}} id="personId" label={t('Person Id')} 
                                {...register('personId')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">                                            
                                      <IconButton color="primary" onClick={handleClickOpenPerson}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton>                                                                                               
                                  </InputAdornment>
                                ) 
                              }} />
                              <TextField sx={{width:'calc(35% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                                {...register(lg.startsWith('fr')?'lastName':'firstName')}  />   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                                {...register(lg.startsWith('fr')?'firstName':'lastName')}  />  
                              <TextField sx={{width:'calc(15% - 8px)'}} id="particularSign" label={t('Part. sign')} 
                                {...register('particularSign')}  />                              
                                { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {setOpenPersonFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPerson } />
                              </FormDialog> }

                            </Box>                                                   
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='gender' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="gender"
                                        label={t('Gender')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_GENDER ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller name='civility' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="civility"
                                        label={t('Civility')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_CIVILITY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                              <Controller control={control}
                                  name='birthDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Birth date')} 
                                      onChange={onChange}                     
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    /> )}
                                />
                              <TextField sx={{width:'calc(50% - 8px)'}} id="birthPlace" label={t('Birth place')} 
                                  {...register('birthPlace')}  /> 
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} > 
                              <TextField sx={{width:'calc(15% - 8px)'}} id="portable1" label={`${t('Portable')} 1`} 
                                {...register('portable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                              <TextField sx={{width:'calc(15% - 8px)'}} id="portable2" label={`${t('Portable')} 2`} 
                                {...register('portable2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="email1" label={`${t('Email')} 1`} 
                                {...register('email1')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                              <TextField sx={{width:'calc(35% - 8px)'}} id="email2" label={`${t('Email')} 2`} 
                                {...register('email2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>                                                    
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Stack flexDirection='row'>                
                                <Box sx={{ mt: 1, width: '50%' }} >
                                  <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                                    accept="image/*" />
                                  <div key={'step.id'}>
                                    <Box
                                      component="img"
                                      sx={carouselImage}
                                      //src={`data:image/png;base64,${watchBase64Picture}`}
                                      src={isFalsy(watchBase64Picture)?`/images/person.jpg`:`data:image/png;base64,${watchBase64Picture}`}
                                      onClick={openFileDialog}
                                      //alt={step.id}
                                    ></Box>
                                  </div>
                                  {/* <img  width='100%'
                                    onClick={openFileDialog} height='100%' maxHeight='250px' /> */}
                                </Box>
                                <Box sx={{ mt: 1, width: '50%' }} >
                                  <Stack flexDirection='column'>                
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <Controller name='defaultLanguage' control={control}                                     
                                            render={ ({field: {onChange, value}}) => (
                                              <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="defaultLanguage"
                                                label={t('Language')} inputProps={ {readOnly: false}} focused >
                                                {enumItems && enumItems.filter( e => 
                                                      e.enumerationCode === Enum_DEFAULT_LANGUAGE ).map( 
                                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                }
                                              </TextField>
                                            )}
                                        />                                    
                                      <Controller name='nationality' control={control}                                     
                                            render={ ({field: {onChange, value}}) => (
                                              <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="nationality"
                                                label={t('Nationality')} inputProps={ {readOnly: false}} focused >
                                                {enumItems && enumItems.filter( e => 
                                                      e.enumerationCode === Enum_COUNTRY ).map( 
                                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                }
                                              </TextField>
                                            )}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <Controller name='identificationType' control={control}                                     
                                            render={ ({field: {onChange, value}}) => (
                                              <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="defaultLanguage"
                                                label={t('Identification type')} inputProps={ {readOnly: false}} focused >
                                                {enumItems && enumItems.filter( e => 
                                                      e.enumerationCode === Enum_PERSON_IDENTIFICATION ).map( 
                                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                }
                                              </TextField>
                                            )}
                                        />
                                        <TextField sx={{width:'calc(50% - 8px)'}} id="identificationNumber" label={`${t('Number')}`}
                                          {...register('identificationNumber')}  />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="identificationDelivrancePlace" label={`${t('Delivrance place')}`}
                                          {...register('identificationDelivrancePlace')}  />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <Controller control={control}
                                        name='identificationStartDate' 
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                          <DatePicker label={t('Start date')} 
                                            onChange={onChange}                     
                                            value={value}
                                            renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                                          /> )}
                                      />
                                      <Controller control={control}
                                      name='identificationEndDate' 
                                      render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <DatePicker label={t('End date')} 
                                          onChange={onChange}                     
                                          value={value}
                                          renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                                        /> )}
                                    />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      
                                    </Box>
                                  </Stack>
                                </Box>
                              </Stack>
                            </Box>
                            
                            
                            
                            
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={4} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>   
                      { ((getValues().id || 0) > 0) &&<Box display="flex" justifyContent="center" flexWrap="wrap" gap={1} sx={{ mt: 1, width: '100%' }} flexDirection='row' >
                          <WidgetSummaryBasic title={`${t('Active commissions')}`} paletteColor={theme.palette['info']} 
                            iconName='IoSendSharp' total={financialSituation.activeCommissionAmount} />
                          <WidgetSummaryBasic title={`${t('Active payments')}`} paletteColor={theme.palette['success']} 
                            iconName='IoSendSharp' total={financialSituation.activePaymentAmount}  />                          
                          <WidgetSummaryBasic title={`${t('Active deductions')}`} paletteColor={theme.palette['warning']} 
                            iconName='IoSendSharp' total={financialSituation.activeDeductionAmount} />
                        </Box> }
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                  label={t('Technician ?')}
                                  control={
                                  <Controller
                                      name='isTechnician'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                          {watchIsTechnician && 
                          <>
                            <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget); }}
                                sx={{ ml: 1, width: '48%', backgroundColor: getValues().appointmentColor}}>
                                {t('Appointment visualisation color')}
                            </Button>
                            <Popover
                              open={Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              onClose={() => {setAnchorEl(null);}}
                            >
                              <ChromePicker color={getValues().appointmentColor} 
                                    onChange={(newColor: ColorResult) => {setValue('appointmentColor',newColor.hex); }} />
                            </Popover>
                          </> }
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller name='specialization' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="specialization"
                                        label={t('Specialization')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_EMPLOYEE_SPECIALIZATION ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                          <Controller name='functionCode' control={control}                                     
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="functionCode"
                                  label={t('Function')} inputProps={ {readOnly: false}} focused >
                                  {enumItems && enumItems.filter( e => 
                                        e.enumerationCode === Enum_EMPLOYEE_FUNCTION ).map( 
                                    (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                  }
                                </TextField>
                              )}
                          />
                        </Box>   
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                  label={t('Bonus on task ?')}
                                  control={
                                  <Controller
                                      name='hasBonusOnTaskExecuted'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                          <Controller
                            render={({ field: {onChange, onBlur, name, value, ref} }) => {
                            return (
                              <NumberFormat              
                                disabled={false}
                                label={t('Min. amount for bonus')} sx={{width:'calc(50% - 8px)'}} //disabled={true}
                                allowEmptyFormatting={false}
                                control={control}    
                                thousandSeparator={true}
                                decimalScale={0}
                                onValueChange={ (v) => onChange(v.floatValue) }
                                defaultValue={value}
                                value={value}
                                //helperText={t('Minimum amount of commission to disburse')}

                                customInput={TextFieldRight}                            
                              />
                            );
                          }}
                          name='minimumAmountForBonus'
                          control={control}
                        />
                        </Box>    
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <TextField sx={{width:'calc(100% - 8px)'}} id="bonusAmountExpression" label={`${t('Expression')} - ${t('Bonus')}`} 
                                multiline rows={2}
                                  {...register('bonusAmountExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={(event) =>
                                            handleClickOpenExpression(event, 'bonus', 'Employee', 'numeric',getValues().bonusAmountExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                              />
                              { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={`${t('Expression')} ...${t('Bonus')}`} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                    <EntityExpression entityName={currentEntityName} properties={[]} 
                                      expression={currentExpression} setExpression={setCurrentExpression} />
                                  </FormDialog>  }
                        </Box>                 
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <ArrayFieldTableEx<IEmployee,IEmployeeTask,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headEmployeeTaskCells} rowsPathName='employeeTasks' 
                                title={t('Tasks of employee')} rowActionIcon={employeeTaskRowActionIcon}  
                                //onRowSelected={handleRoleEntitySelected}
                                                    
                                refAppend={refAppendEmployeeTasks as MutableRefObject<(value: Partial<FieldArray<IEmployee>> | Partial<FieldArray<IEmployee>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateEmployeeTask as MutableRefObject<(index: number,value: Partial<FieldArray<IEmployee>>) => void>}
                                refRemove={refRemoveEmployeeTask as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddEmployeeTasks ,icon: AddCircleIcon,  },
                                
                                ]}
                                canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                            />
                        </Box> 
                        
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

