import { IAppTheme, defaultAppTheme } from "themes/commonStyles"


export interface IApplicationSetup {
    id: number,
    companyName: string,
    description: string,
    

    isPasswordComplexityAllowed: boolean,
    passwordDuration: number,
    passwordRotation: number,
    maxAccessFailedCount: number,
    accessLockoutDuration: number,
    
    applicationApiToken: string,

    whatsAppBaseUrl: string,
    whatsAppIdentityToken: string,
    whatsAppLogin: string,
    whatsAppPasswordMd5: string,

    whatsAppPassword: string,
    isPasswordChanged: boolean,

    webhookToken: string,
    webhookUrl: string,

    isCommissionDisburseOn: boolean,
    minimumDisbursementCommissionAmount: number,

    valueOfPoint: number,
    minimumDisbursementPointCount: number,

    minAppointmentStartTime: Date,
    maxAppointmentStartTime: Date,

    certificateSmsExpression: string,

    certificateDocumentExpression: string,
    certificateImageExpression: string,
    certificateQrCodeExpression: string,    
    
    isSmsOn: boolean,
    isWhatsAppOn: boolean,
    isReportScheduleOn: boolean,
    isFeatureScheduleOn: boolean,
    notificationWhatsAppNumber1: string,
    notificationWhatsAppNumber2: string,

    // issuePeriod: number,
    // isAutomaticIssueDate: boolean,
    // issueDateMaxDelayMonthClosing: number,
    // issueDateMaxDelayYearClosing: number,
    // isAutomaticClosedAfterDelay: boolean,
    // proformaNumberExpression: string,
    // billingNumberExpression: string,
    // deliveryNumberExpression: string,
    // resetProformaSequenceByYear: boolean,
    // resetBillingSequenceByYear: boolean,
    // resetDeliverySequenceByYear: boolean,

    // newMessageColor: string,
    // nonTicketableMessageColor: string,
    // ticketableMessageColor: string,
    // joinedMessageColor: string,

    hasService: boolean,
    hasArticle: boolean,
    hasPackaging: boolean,
    hasFormation: boolean,
    hasMaintenance: boolean,
    hasContract: boolean,

    applicationTheme: IAppTheme
  }


  export const defaultApplicationSetup : IApplicationSetup = {
    id: 0,
    companyName: '',
    description: '',
    
    isPasswordComplexityAllowed: false,
    passwordDuration: 0,
    passwordRotation: 0,
    maxAccessFailedCount: 0,
    accessLockoutDuration: 0,

    applicationApiToken: '',
    
    whatsAppBaseUrl: '',
    whatsAppIdentityToken: '',
    whatsAppLogin: '',
    whatsAppPasswordMd5: '',

    whatsAppPassword: '',
    isPasswordChanged: false,

    webhookToken: '',
    webhookUrl: '',

    isCommissionDisburseOn: true,
    minimumDisbursementCommissionAmount: 10000,

    valueOfPoint: 0,
    minimumDisbursementPointCount: 0,

    minAppointmentStartTime: new Date(),
    maxAppointmentStartTime: new Date(),

    certificateSmsExpression: '',

    certificateDocumentExpression: '',
    certificateImageExpression: '',
    certificateQrCodeExpression: '',

    isSmsOn: true,
    isWhatsAppOn: true,
    isReportScheduleOn: true,
    isFeatureScheduleOn: true,
    notificationWhatsAppNumber1: '',
    notificationWhatsAppNumber2: '',

    // issuePeriod: (new Date()).getFullYear()*100+1,
    // isAutomaticIssueDate: true,
    // issueDateMaxDelayMonthClosing: 0,
    // issueDateMaxDelayYearClosing: 0,
    // isAutomaticClosedAfterDelay: false,
    // proformaNumberExpression: '',
    // billingNumberExpression: '',
    // deliveryNumberExpression: '',
    // resetProformaSequenceByYear: true,
    // resetBillingSequenceByYear: true,
    // resetDeliverySequenceByYear: true,

    // newMessageColor: '#4CAF50',
    // nonTicketableMessageColor: '#4CAF50',
    // ticketableMessageColor: '#4CAF50',
    // joinedMessageColor: '#4CAF50',

    hasService: false,
    hasArticle: false,
    hasPackaging: false,
    hasFormation: false,
    hasMaintenance: false,
    hasContract: false,

    applicationTheme: defaultAppTheme
  }

  

  export interface IApplicationSetupSearch {
    companyName: string
  }