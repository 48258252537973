

export interface IEmployee {

    id: number,
    
    personId: number,
    registrationNumber: string,
    hiringDate: Date,
    status: string,
    statusDate: Date,

    lastDiploma: string,
    specialization: string,
    diplomaDate: Date,

    isTechnician: boolean,
    appointmentColor: string,
    //isDriver: boolean,

    serviceCode: string,
    functionCode: string,
    employeeType: string,

    category: string,
    managerEmployeeId: number,
    managerEmployeeFullName: string,

    hasBonusOnTaskExecuted: boolean,
    minimumAmountForBonus: number,
    bonusAmountExpression: string,
           
    firstName: string,
    lastName: string,
    particularSign: string,
    birthDate: Date,
    birthPlace: string,
    civility: string,
    gender: string,

    portable1: string,
    portable2: string,
    email1: string,
    email2: string,

    defaultLanguage: string,

    nationality: string,
    identificationType: string,
    identificationNumber: string, 
    identificationDelivrancePlace: string, 
    identificationStartDate: Date, 
    identificationEndDate: Date,

    managerFirstName: string,
    managerLastName: string,
    managerBirthDate: Date,

    base64Picture: string,

    employeeTasks: IEmployeeTask[]
  }

  export interface IEmployeeTask {
    id: number,
    isActive: boolean,
  
    employeeId: number,
    taskCode: string,
    
  }

  
  export const defaultEmployee : IEmployee = {
    id: 0,
    
    personId: 0,
    registrationNumber: '',
    hiringDate: new Date(),
    status: '10',
    statusDate: new Date(),

    lastDiploma: '',
    specialization: '',
    diplomaDate: new Date(),
    
    isTechnician: true,
    appointmentColor: '',
    //isDriver: false,

    serviceCode: '',
    functionCode: '',
    employeeType: '',

    category: '',
    managerEmployeeId: 0,
    managerEmployeeFullName: '',

    hasBonusOnTaskExecuted: false,
    minimumAmountForBonus: 0,
    bonusAmountExpression: '',

    firstName: '',
    lastName: '',
    particularSign: '',
    birthDate: new Date(),
    birthPlace: '',
    civility: '',
    gender: '',
    
    portable1: '',
    portable2: '',
    email1: '',
    email2: '',

    defaultLanguage: '',

    nationality: '',
    identificationType: '',
    identificationNumber: '', 
    identificationDelivrancePlace: '', 
    identificationStartDate: new Date(), 
    identificationEndDate: new Date(),

    managerFirstName: '',
    managerLastName: '',
    managerBirthDate: new Date(),

    base64Picture: '',

    employeeTasks: []
  }

  export interface IEmployeeSearch {
    
    firstName: string,
    lastName: string,        
  }

  export interface IEmployeeFinancialSituation {
    activeCommissionAmount: number, 
    activePaymentAmount: number, 
    activeDeductionAmount: number
  }

export const defaultEmployeeFinancialSituation : IEmployeeFinancialSituation = {
    activeCommissionAmount: 0, 
    activePaymentAmount: 0, 
    activeDeductionAmount: 0
}