
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { addMinutes } from 'date-fns';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom,currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useAppointmentProduct, { useBasicFilterAppointment } from './services/Appointment';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IAppointment, IAppointmentProduct, defaultAppointment } from './models/Appointment';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import usePersonService, {useBasicFilterPerson, useBasicFilterPersonSearch}  from 'features/production/services/Person';

import IEnumeration,  { IEnumerationItem, Enum_RESOURCE_CATEGORY, Enum_APPOINTMENT_NOTIFICATION_MODE, Enum_APPOINTMENT_STATUS, Enum_APPOINTMENT_STATUS_PURPOSE } from 'features/configuration/models/Enumeration';

import useProductService, { useBasicFilterProduct } from 'features/setup/services/Product';
import { IProduct } from 'features/setup/models/Product';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { DatePicker } from '@mui/x-date-pickers';
import { IPerson } from './models/Person';

import { useBasicFilterEmployee } from 'features/setup/services/Employee';
import { IEmployee } from 'features/setup/models/Employee';
import { debounce } from 'lodash';
import { GrSearch, GrClose } from 'react-icons/gr';

export const AppointmentForm: FC<IAppointment> = (props: IAppointment = defaultAppointment) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();

  const {language: lg, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const { getPersonsSearchCount } = usePersonService();

  const { getServiceTasksByService, getServiceTasksByPackaging } = useProductService();

  const { createAppointment, updateAppointment } = useAppointmentProduct();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterAppointment = useBasicFilterAppointment( 
    (event: React.MouseEvent<unknown>, row: IAppointment) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
  const basicFilterPerson = useBasicFilterPerson( 
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, type, firstName, lastName, birthDate, birthPlace, 
            particularSign, corporationName, corporationType,
            portable1, portable2, email1, email2} = row;
        
        setValue('personId', id);

        setValue('firstName', firstName);
        setValue('lastName', lastName); 
        setValue('portable1', portable1);
        setValue('portable2', portable2);

        setValue('email1', email1);
        setValue('email2', email2);     

        setOpenPersonFilter(false);
      }
  );

  const [openProductFilter, setOpenProductFilter] = useState(false);
  const basicFilterProduct = useBasicFilterProduct( 13,
      (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, description,name, serviceDurationInMinute, type} = row;

          if(type === 'article' || type === 'formation' || type === 'maintenance' || type === 'contract') return;

          const startTime = new Date();
          const endTime =  addMinutes(startTime, serviceDurationInMinute);

          (refAppendAppointmentProducts.current??emptyFunc)({id: 0, appointmentId: _id, employeeId: 0,
            productId: id, 
            startTime , endTime , serviceDurationInMinute, productName: name , employeeFirstName: '', employeeLastName: '', employeeFullName: ''  });                          
            
          setOpenProductFilter(false);
      }
  );

  const [openEmployeeFilter, setOpenEmployeeFilter] = useState(false);
  const basicFilterEmployee = useBasicFilterEmployee( 
      async (event: React.MouseEvent<unknown>, row: IEmployee) => {
        const {id, firstName, lastName, employeeTasks} = row;
        
        const appointmentProduct = getValues().appointmentProducts.at(productIndex);        
        if(isFalsy(appointmentProduct)) return;

        console.log(appointmentProduct);
        const {type, productId} = appointmentProduct;
        var tasks = await getServiceTasksByService(productId) ;
        
        var maxPart = Math.max(...tasks.map(t => t.part*t.servicePart), 0);
        var mainTasks = tasks.filter(t => t.part*t.servicePart === maxPart);

        if( !employeeTasks.some(et => mainTasks.some(st => st.taskCode === et.taskCode)) ) {          
            enqueueSnackbar( t('This service or package can not be assign to this employee'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
            return;        
        }
               
        (refUpdateAppointmentProduct.current??emptyFunc)(productIndex, 
            {...appointmentProduct, employeeId: id, employeeFirstName: firstName, employeeLastName: lastName,
              
              employeeFullName: `${lg.includes('fr')? lastName:firstName} ${lg.includes('fr')? firstName:lastName}`.trim() });  

        setOpenEmployeeFilter(false);
      }
  );
    
  const methods = useForm<IAppointment>({defaultValues:defaultAppointment});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchPersonId = watch('personId');

  type SearchPersonUsage = 'person';
  const [currentSearchPersonUsage, setCurrentSearchPersonUsage] = useState<SearchPersonUsage>('person');

  // const watchBase64Picture = watch('base64Picture');
   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });

  const [openPersonSearchFilter, setOpenPersonSearchFilter] = useState(false);
  const basicFilterPersonSearch = useBasicFilterPersonSearch( 
      () => {
        const { firstName, lastName } = getValues();

        if(currentSearchPersonUsage === 'person')
          return {firstName, lastName, particularSign: ''}; 

        return {firstName: '@@@@', lastName: '@@@@', particularSign: '@@@@'};         
      },      
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, firstName, lastName, birthDate, birthPlace, particularSign,
            portable1, portable2, email1, email2} = row;
        
        if(currentSearchPersonUsage === 'person') {
          
          setValue('personId', id);
          setValue('firstName', firstName);
          setValue('lastName', lastName);  
          setValue('portable1', portable1);   
          setValue('portable2', portable2);                    
          setValue('email1', email1); 
          setValue('email2', email2); 
        }          
                          
        setOpenPersonSearchFilter(false);
      }
  );
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IAppointment>,Error,IAppointment>(
      _id>0?updateAppointment:createAppointment, {   
        onSuccess: (data: IResult<IAppointment>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Appointment',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IAppointment>(['Appointment', _id], () => retrieveEntity('Appointment',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Appointment'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_APPOINTMENT_NOTIFICATION_MODE, Enum_APPOINTMENT_STATUS, Enum_APPOINTMENT_STATUS_PURPOSE ] ));

    const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

    const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
       setValueTabCoverage(newValue);       
    };
      
    const handleClickOpenPerson = async (event: any) => {
      setOpenPersonFilter(true);
    }

    const handleClickRemovePerson = async (event: any) => {
      setValue('personId', 0);
      setValue('firstName', '');
      setValue('lastName', '');
      setValue('portable1', '');
      setValue('portable2', '');
      setValue('email1', '');
      setValue('email2', '');
    }
    // const getServiceTaskCode = (row: IAppointmentProduct, cellId: keyof IAppointmentProduct, 
    //   opts: {value: string, name: string}[]) => {        
      
    //     return getAsOptions(refEnumItems.current ?? [],Enum_SERVICE_TASK);
    // }

    const [productIndex, setProductIndex] = useState<number>(-1);
    const getInputAdornmentEmployee = (row: IAppointmentProduct, cellId: keyof IAppointmentProduct)  => ({
  
      toolTip: 'Subject',
      icon: ArrowDropDownCircleIcon,
      //iconDisable: row.resourceId > 0,
      onClickIcon: (event: any, index: number, row: IAppointmentProduct ) => {
        setProductIndex(index);
        setOpenEmployeeFilter(true);
      }  
    })

    

    const cellEditableAppointmentProduct = (row: IAppointmentProduct, cellId: keyof IAppointmentProduct) => {      
      return true;
    }

    const [headAppointmentProductCells, setHeadAppointmentProductCells]  = useState<HeadCell<IAppointmentProduct>[]>([      
      //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
      {id:'productName', label : t('Service'),  display: true, type: 'string', width: 35, 
        isEditable: cellEditableAppointmentProduct},
      {id:'employeeFullName', label : t('Employee'),  display: true, type: 'string', width: 35, 
        getInputAdornment: getInputAdornmentEmployee},

      {id:'startTime', label : t('Start'),  display: true, type: 'datetime', width: 15,
         minTime: applicationSetup.minAppointmentStartTime, maxTime: applicationSetup.maxAppointmentStartTime,
         isEditable: cellEditableAppointmentProduct},
      {id:'endTime', label : t('End'),  display: true, type: 'datetime', width: 15, },           
    ]);

    const refAppendAppointmentProducts = useRef<(value: Partial<FieldArray<IAppointment>> | Partial<FieldArray<IAppointment>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateAppointmentProduct = useRef<(index: number,value: Partial<FieldArray<IAppointment>> ) => void>(null);
    const refRemoveAppointmentProduct = useRef<(index: number ) => void>(null);
      
    const handleAppointmentProductSelected = (event: React.MouseEvent<unknown>,index: number,row: IAppointmentProduct) => {
      setProductIndex(index);
    }

    const handleAddAppointmentProducts = (event: any) => {
      //(refAppendAppointmentProducts.current??emptyFunc)({id: 0, resourceTypeId: _id, taskCode: '', part: 0,  });
      setOpenProductFilter(true);
    }

    // const handleAddNewRessources = (event: any) => {
    //   (refAppendAppointmentProducts.current??emptyFunc)({id: 0, maintenanceId: _id, resourceId: 0,
    //     resourceDescription: '', resourceTypeId: 0, resourceTypeName: '',
    //     maintenanceReference: '', maintenanceDescription: '', maintenanceSupplierFullName: '',
    //     description: '', amount: 0,  });
    // }

    const appointmentProductRowActionIcon = ( row: IAppointmentProduct) : ActionIconTableRow<IAppointment,IAppointmentProduct> => {
  
      const res: ActionIconTableRow<IAppointment,IAppointmentProduct> = {
        toolTip: 'viewDetails',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IAppointmentProduct) => {
          
           (refRemoveAppointmentProduct.current??emptyFunc)(index);            
        }
      }
      return res;
  }


  useEffect(() => {
    
    const subscription = watch( (value, { name, type }) => {
      
      if( (name?.endsWith('firstName') || name?.endsWith('lastName')) ) {
          
        setCurrentSearchPersonUsage('person');         
        debouncedPersonChange();                          
      } 
    });  

    return () => subscription.unsubscribe();
  }, [watch]);

  const debouncedPersonChange = useRef(
    debounce( async () => {      
      const { personId, firstName, lastName } = getValues();

      if(personId>0) return;

      if( (isFalsy(firstName) || firstName === '') && (isFalsy(lastName) || lastName === '' )) return;

      const count = await getPersonsSearchCount(firstName, lastName, '');

      if(!isFalsy(refPersonSnackbarId.current)) closeSnackbar(refPersonSnackbarId.current!);
      
      if(count > 0) 
        refPersonSnackbarId.current = enqueueSnackbar( `${t('Identity')} --> ${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
              anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
              action: xActionPersonSearch('person') } );             

    }, 2500)
  ).current;

  const refPersonSnackbarId = useRef<SnackbarKey>();
  
  const xActionPersonSearch = (searchPersonUsage: SearchPersonUsage): SnackbarAction => (snackbarId: SnackbarKey) => (
    <>        
        <Button onClick={() => { 
                // setUrlPdf(`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`); 
                setCurrentSearchPersonUsage(searchPersonUsage);                  
                setOpenPersonSearchFilter(true);
                closeSnackbar(snackbarId); }}>   
            <GrSearch size={24} />
        </Button>
        <Button onClick={() => { closeSnackbar(snackbarId) }}>
            <GrClose size={24} />
        </Button>
    </>
)

  

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems]);


  useEffect(() => {
    const subscription = watch( (value, { name, type }) => {
    
      if(name?.includes('startTime') && name.includes('appointmentProducts') ) {
              
        const service = getValues().appointmentProducts.at(productIndex);        
        if(isFalsy(service)) return;
       
        const {startTime, endTime, serviceDurationInMinute} = service!;
        
        (refUpdateAppointmentProduct.current??emptyFunc)(productIndex, 
            {...service, endTime: addMinutes(startTime, serviceDurationInMinute) });
      }    
    });  
  
    return () => subscription.unsubscribe();
  }, [watch, productIndex]);


      useEffect( () => {        
        setCurrentFormNameAtom(t('Appointment'));
        setCurrentBasicTextFilterProps(basicFilterAppointment);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Appointment',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultAppointment);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        if(!checkEntitySaveAuthorization('Appointment', _id)) {
          setIsSaveLoading(false);
          return;
        }
        const data = getValues(); 

        if( (data.firstName.trim() === '' && data.lastName.trim() === '') ) {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        if( data.portable1 === '' ) {
          enqueueSnackbar( t('Phone number is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        if( data.notificationMode === '' ) {
          enqueueSnackbar( t('Notification mode is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }
              
        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Appointment', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
      queryClient.invalidateQueries(['Appointment',_id]);        
      await retrieveData('Appointment',_id, refetch);        
      reset(_data);         
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={5} lg={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                                <TextField sx={{width:'calc(20% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                                
                                <Controller control={control}
                                  name='appointmentDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Appointment date')} 
                                      onChange={onChange}                    
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>   
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Identification'))} `}
                              </Typography>                                                       
                            </Box>                                                                                                        
                            <Box sx={{ mt: 1, width: '100%' }} key={` person ${getValues().personId}`}>
                              <TextField sx={{width:'calc(15% - 8px)'}} id="personId" label={t('Person Id')} 
                                {...register('personId')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">  
                                    { (isFalsy(watchPersonId) ||  watchPersonId<=0) ?
                                        <IconButton color="primary" onClick={handleClickOpenPerson}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton> : 
                                        <IconButton color="primary" onClick={handleClickRemovePerson}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      }                                                                                                                                     
                                  </InputAdornment>
                                ) 
                              }} />
                              <TextField sx={{width:'calc(50% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                                {...register(lg.startsWith('fr')?'lastName':'firstName')} inputProps={ {readOnly: (watchPersonId>0) }}  />   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                                {...register(lg.startsWith('fr')?'firstName':'lastName')} inputProps={ {readOnly: (watchPersonId>0) }} />  
                                                            
                                { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {setOpenPersonFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPerson } />
                              </FormDialog> }
                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} > 
                              <TextField sx={{width:'calc(15% - 8px)'}} id="portable1" label={`${t('Portable')} 1`} 
                                {...register('portable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                              <TextField sx={{width:'calc(15% - 8px)'}} id="portable2" label={`${t('Portable')} 2`} 
                                {...register('portable2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="email1" label={`${t('Email')} 1`} 
                                {...register('email1')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                              <TextField sx={{width:'calc(35% - 8px)'}} id="email2" label={`${t('Email')} 2`} 
                                {...register('email2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>                                                    
                            
                            { openPersonSearchFilter && <FormDialog open={openPersonSearchFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonSearchFilter(false);}} onOk={()=> {setOpenPersonSearchFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPersonSearch } />
                              </FormDialog> }
                            
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Notification'))} `}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name={`notificationMode`} control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(45% - 8px)'}} id="notificationMode"
                                        label={t('Notification mode')} inputProps={ {readOnly: false}} >                                        
                                          {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_APPOINTMENT_NOTIFICATION_MODE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }                                   
                                      </TextField>
                                    )}
                                />
                              <Controller control={control}
                                  name='notificationDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Date')} 
                                      onChange={onChange}                    
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                                                            
                              
                            </Box>              
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='status' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                        label={t('Status')} inputProps={ {readOnly: true}} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_APPOINTMENT_STATUS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              <Controller control={control}
                                name='statusDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Status date')} 
                                    onChange={onChange} disableOpenPicker readOnly                    
                                    value={value}
                                    renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                  /> )}
                              />
                              <Controller name='statusPurpose' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="status"
                                        label={t('Status purpose')} inputProps={ {readOnly: true}} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_APPOINTMENT_STATUS_PURPOSE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                            </Box>
                            
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={`${'watchFileName'} - key`} sx={{ mt: 1, width: '100%' }} >  
                          <ArrayFieldTableEx<IAppointment,IAppointmentProduct,'id'> 
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headAppointmentProductCells} rowsPathName='appointmentProducts' 
                              title={t('Add service(s) for this appointment')} rowActionIcon={appointmentProductRowActionIcon}  
                              onRowSelected={handleAppointmentProductSelected}
                                                  
                              refAppend={refAppendAppointmentProducts as MutableRefObject<(value: Partial<FieldArray<IAppointment>> | Partial<FieldArray<IAppointment>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateAppointmentProduct as MutableRefObject<(index: number,value: Partial<FieldArray<IAppointment>>) => void>}
                              refRemove={refRemoveAppointmentProduct as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={[

                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddAppointmentProducts ,icon: AddCircleIcon,  },
                              
                              ]}
                              canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                          />
                          { openProductFilter && <FormDialog open={openProductFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Service')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenProductFilter(false);}} onOk={()=> {setOpenProductFilter(false);}}  >
                                      <BasicTextFilterForm<IProduct> {...basicFilterProduct } />
                              </FormDialog> }
                          { openEmployeeFilter && <FormDialog open={openEmployeeFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Employee')} onCancel={()=> {}} 
                              onClose={()=> {setOpenEmployeeFilter(false);}} onOk={()=> {setOpenEmployeeFilter(false);}}  >
                                  <BasicTextFilterForm<IEmployee> {...basicFilterEmployee } />
                          </FormDialog> }
                        </Box>                        
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

