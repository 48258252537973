
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { debounce, sum } from 'lodash';

import { addMinutes } from 'date-fns';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import CopyAllIcon from '@mui/icons-material/CopyAll';

import CheckIcon from '@mui/icons-material/Check';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

import LocalMallIcon from '@mui/icons-material/LocalMall';
import SchoolIcon from '@mui/icons-material/School';
import DescriptionIcon from '@mui/icons-material/Description';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import ArchiveIcon from '@mui/icons-material/Archive';

import { MdOutlineAdd, MdOutlineCancel, MdOutlineCheckCircle } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom,currentUserSessionAtom, 
      isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useBillingService, { useBasicFilterBilling } from './services/Billing';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IBilling, IBillingDetail, defaultBilling, defaultBillingDetail } from './models/Billing';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration,  { IEnumerationItem, Enum_SERVICE_TASK, Enum_BILLING_STATUS, Enum_BILLING_STATUS_PURPOSE,
  Enum_ARTICLE_OPTION, Enum_ARTICLE_OPTION_CLASS, Enum_PRICE_PURPOSE, 
  Enum_MAINTENANCE_CATEGORY, Enum_CONTRACT_SCOPE, Enum_CONTRACT_TYPE, Enum_CORPORATION_TYPE } from 'features/configuration/models/Enumeration';

import useProductService, { useBasicFilterProduct } from 'features/setup/services/Product';

import usePersonService, {useBasicFilterPerson, useBasicFilterPersonSearch}  from 'features/production/services/Person';

import { IProduct } from 'features/setup/models/Product';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { IPerson, defaultPerson } from 'features/production/models/Person';


import { useBasicFilterEmployee } from 'features/setup/services/Employee';
import { IEmployee } from 'features/setup/models/Employee';


import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';

export const BillingForm: FC<IBilling> = (props: IBilling = defaultBilling) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();

  const {language: lg, userDescription, userName, roleEntities, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization, openEntityPrintDrawer } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const { getPersonsSearchCount, getBirthDateType } = usePersonService();


  const { createBilling, updateBilling, evaluateAddedProduct,  } = useBillingService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterBilling = useBasicFilterBilling( 
    (event: React.MouseEvent<unknown>, row: IBilling) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
  const basicFilterPerson = useBasicFilterPerson( 
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, type, firstName, lastName, birthDate, birthPlace, 
            particularSign, corporationName, corporationType,
            portable1, portable2, email1, email2} = row;
        
        if(currentPersonUsage === 'customer') {
          setValue('customerId', id);

          setValue('customerFirstName', firstName);
          setValue('customerLastName', lastName); 
          setValue('customerPortable1', portable1);
          setValue('customerPortable2', portable2);
          setValue('customerEmail1', email1);
          setValue('customerEmail2', email2);
        } 

        setOpenPersonFilter(false);
      }
  );
  

  const [openProductFilter, setOpenProductFilter] = useState(false);
  const basicFilterService = useBasicFilterProduct( 1,
      async (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, description,name, serviceDurationInMinute, lineOfBusinessCode} = row;
                                   
          const countBillingDetail = getValues().billingDetails.length;
          (refAppendBillingDetails.current??emptyFunc)({...defaultBillingDetail, productId: id,  
            productName: name, lineOfBusinessCode });                          
                      
          setTotalAmount();
          setProductIndex(countBillingDetail);
          setOpenProductFilter(false);
      }
  );


  
  const [openEmployeeFilter, setOpenEmployeeFilter] = useState(false);
  const basicFilterEmployee = useBasicFilterEmployee( 
      (event: React.MouseEvent<unknown>, row: IEmployee) => {
        const {id, firstName, lastName, managerFirstName, managerLastName, employeeTasks, } = row;        
        
        if(currentEmployeeUsage === 'business') {

          setValue('businessEmployeeId', id);
          setValue('businessEmployeeFullName', `${lastName} ${firstName}`.trim());
          const mFirstName = isFalsy(managerFirstName)?'':managerFirstName;
          const mLastName = isFalsy(managerLastName)?'':managerLastName;

          setValue('businessEmployeeManagerFullName', `${mLastName} ${mFirstName}`.trim());
          setOpenEmployeeFilter(false);
          return;
        }

        setOpenEmployeeFilter(false);
      }
  );
    

  const methods = useForm<IBilling>({defaultValues: {...defaultBilling}});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchCustomerId = watch('customerId');
  const watchCustomerPersonType = watch('customerPersonType');
  const watchBusinessEmployeeId = watch('businessEmployeeId');  

  const watchCustomerFirstName = watch('customerFirstName');
  const watchCustomerLastName = watch('customerLastName');
  const watchCustomerCorporationName = watch('customerCorporationName');
  const watchCustomerBirthDateType = watch('customerBirthDateType');
  const watchCustomerCreationDateType = watch('customerCreationDateType');

  

  type SearchPersonUsage = 'customer' | 'billing-detail-beneficiary' | 'billing-packaging-beneficiary';
  const [currentSearchPersonUsage, setCurrentSearchPersonUsage] = useState<SearchPersonUsage>('customer');

  const refProductIndex = useRef<number>(0);
  const refBillingPackagingServiceIndex = useRef<number>(0);

  const [openPersonSearchFilter, setOpenPersonSearchFilter] = useState(false);
  const basicFilterPersonSearch = useBasicFilterPersonSearch( 
      () => {
        const { customerFirstName, customerLastName, customerCorporationName, customerPersonType } = getValues();

        const fn = customerPersonType === 'moral' ? customerCorporationName : customerFirstName;
        const ln = customerPersonType === 'moral' ? customerCorporationName : customerLastName;

        if(currentSearchPersonUsage === 'customer')
          return {firstName: fn, lastName: ln, particularSign: ''}; 
        
        return {firstName: customerFirstName, lastName: customerLastName, particularSign: ''};
        //return refPersonSearch.current || {firstName: '', lastName: '', particularSign: ''};
      },      
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, type, firstName, lastName, birthDate, birthPlace, particularSign, corporationName, corporationType,
            portable1, portable2, email1, email2} = row;
        
        
        if(currentSearchPersonUsage === 'customer') {
          
          setValue('customerId', id);
          setValue('customerPersonType', type);
          setValue('customerFirstName', firstName);
          setValue('customerLastName', lastName);  
          setValue('customerCorporationName', corporationName);
          setValue('customerCorporationType', corporationType);
          setValue('customerPortable1', portable1);   
          setValue('customerPortable2', portable2);                    
          setValue('customerEmail1', email1); 
          setValue('customerEmail2', email2); 
        }         
                          
        setOpenPersonSearchFilter(false);
      }
  );
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IBilling>,Error,IBilling>(
      _id>0?updateBilling:createBilling, {   
        onSuccess: (data: IResult<IBilling>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
          
          
          setIsSaveLoading(false);
          //setValue('id', data.data.id);
          _setId(data.data.id);
          //retrieveData('Billing',data.data.id, refetch);
          setCurrentFormNameAtom(`${t('Billing')} - # ${data.data.id} # ` );
          
          //queryClient.invalidateQueries(['Billing',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

      
    const {data: _data, refetch} = useQuery<IBilling>(['Billing', _id], () => retrieveEntity('Billing',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Billing'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_SERVICE_TASK, Enum_BILLING_STATUS, Enum_BILLING_STATUS_PURPOSE, 
          Enum_ARTICLE_OPTION, Enum_ARTICLE_OPTION_CLASS, Enum_PRICE_PURPOSE, 
          Enum_MAINTENANCE_CATEGORY, Enum_CONTRACT_SCOPE, Enum_CONTRACT_TYPE, Enum_CORPORATION_TYPE  ] ));
   

    type PersonUsage = 'customer' | 'billing-detail-service' | 'billing-packaging-service' | 'billing-detail-formation';

    const [currentPersonUsage, setCurrentPersonUsage] = useState<PersonUsage>('customer');
    const handleClickOpenPerson = async (event: any, usage: PersonUsage) => {
      setCurrentPersonUsage(usage);
      setOpenPersonFilter(true);
    }

    const handleClickRemovePerson = async (event: any, usage: PersonUsage) => {

      if(usage === 'customer') {
        setValue('customerId', 0);
        setValue('customerFirstName', '');
        setValue('customerLastName', '');
        setValue('customerCorporationName', '');
        setValue('customerCorporationType', '');
        setValue('customerPortable1', '');
        setValue('customerPortable2', '');
        setValue('customerEmail1', '');
        setValue('customerEmail2', '');

      }
    } 

        

    type EmployeeUsage = 'business' | 'billing-detail-service' | 'billing-packaging-service' | 'billing-service' | 'billing-packaging' | 'fee';
    const [currentEmployeeUsage, setCurrentEmployeeUsage] = useState<EmployeeUsage>('billing-detail-service');

    const handleClickSelectEmployee = (event: any, employeeUsage: EmployeeUsage) => {
      setCurrentEmployeeUsage(employeeUsage);      
      setOpenEmployeeFilter(true);
    }

    const handleClickResetEmployee = (event: any, employeeUsage: EmployeeUsage) => {
      
        if(employeeUsage === 'business') {
          setValue('businessEmployeeId', 0);
          setValue('businessEmployeeFullName', '');
          setValue('businessEmployeeManagerFullName', '');
        }
    }

    const [productIndex, setProductIndex] = useState<number>(-1);
        
    const getArticleOptionBillingDetailList = (row: IBillingDetail, cellId: keyof IBillingDetail, 
      opts: {value: string, name: string}[]) => {        
      
      const {articleFilterOption} = row;
      const enumsFilter = (refEnumItems.current ?? []).filter(x => x.parentEnumerationItemCode === articleFilterOption);
    
      return getAsOptions(enumsFilter ,Enum_ARTICLE_OPTION);
    }

    const cellEditableBillingDetail = (row: IBillingDetail, cellId: keyof IBillingDetail) => { 
      
      // if(row.type === 'article' && cellId === 'quantity') return true;
      // if(row.type === 'article' && cellId === 'articleOption') return true;

      return true;
    }

    const cellAllowedDiscount = (row: IBillingDetail, valueAmount: number) => {
      const { loaded} = row;      
            
      debouncedAmount();
      return true;
    }

    const cellAllowedLoaded = (row: IBillingDetail, valueAmount: number) => {
      
      debouncedAmount();
      return true;
    }

    const debouncedAmount = useRef(
      debounce( () => {
        const index = refProductIndex.current;

          const billingDetail = getValues().billingDetails.at(index); 
          if(isFalsy(billingDetail)) return; 

          const {discount, loaded, baseAmount} = billingDetail!;

          const amount = baseAmount-discount+loaded;
          const newBaseAmount = baseAmount;

          (refUpdateBillingDetail.current??emptyFunc)(index, {...billingDetail!, 
              baseAmount: newBaseAmount, netAmount: amount, amount, tax: 0 });  
        setTotalAmount();
      }, 700)
    ).current;

    const [headBillingDetailCells, setHeadBillingDetailCells]  = useState<HeadCell<IBillingDetail>[]>([   
      
      {id:'productName', label : t('Product'),  display: true, type: 'string', width: 40}, 

      {id:'baseAmount', label : t('Base'),  display: true, type: 'numeric', width: 8, isEditable: cellEditableBillingDetail}, 
      {id:'discount', label : t('Discount'),  display: true, type: 'numeric', width: 8, 
        isEditable: cellEditableBillingDetail, isAllowed: cellAllowedDiscount}, 
      {id:'loaded', label : t('Loaded'),  display: true, type: 'numeric', width: 8, 
        isEditable: cellEditableBillingDetail, isAllowed: cellAllowedLoaded}, 
      {id:'isDelivered', label : t('Done ?'),  display: true, type: 'boolean', width: 9, isEditable: cellEditableBillingDetail}, 
      {id:'deliveryDate', label : t('Execution date'),  display: true, type: 'date', width: 10, isEditable: cellEditableBillingDetail}, 
      {id:'isDeliveryDateDefine', label : t('Execution date ?'),  display: true, type: 'boolean', width: 9, isEditable: cellEditableBillingDetail}, 

      {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 8, },           
    ]);

    const refAppendBillingDetails = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateBillingDetail = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
    const refRemoveBillingDetail = useRef<(index: number ) => void>(null);
      
    const handleBillingDetailSelected = (event: React.MouseEvent<unknown>,index: number,row: IBillingDetail) => {
      billingDetailSelected(index);
    }

    const billingDetailSelected = async (index: number) => {
      
      setProductIndex(index);
    }

    const handleAddProducts = (event: any) => {      
      setOpenProductFilter(true);
    }

        

    // const handleAddNewRessources = (event: any) => {
    //   (refAppendBillingDetails.current??emptyFunc)({id: 0, maintenanceId: _id, resourceId: 0,
    //     resourceDescription: '', resourceTypeId: 0, resourceTypeName: '',
    //     maintenanceReference: '', maintenanceDescription: '', maintenanceSupplierFullName: '',
    //     description: '', amount: 0,  });
    // }
    const  [openBillingDetailServiceDelivery, setOpenBillingDetailServiceDelivery] = useState<boolean>(false);

    const billingDetailRowActionIcon = ( row: IBillingDetail) : ActionIconTableRow<IBilling,IBillingDetail> => {
      
      const roleEntityBilling = roleEntities.find(re => re.entityName === 'Billing');

      const canDeliverService = !isFalsy(roleEntityBilling) && 
                          roleEntityBilling.roleEntityFeatures.some(f => f.featureName === 'DeliverBillingDetailBillingHandler');

      const res: ActionIconTableRow<IBilling,IBillingDetail> = {
        toolTip: 'viewDetails',
        icon: getValues().status === '95'? RemoveCircleIcon : CheckIcon,
        hasAction: (getValues().status === '95') || (getValues().status === '10' && !row.isDelivered && canDeliverService ),  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IBillingDetail) => {
          
          setProductIndex(index);

          if(getValues().status === '95') {
            if(index>0)
              billingDetailSelected(index-1);  
            else if(getValues().billingDetails.length>1)
              billingDetailSelected(index+1);  
            else billingDetailSelected(index-1); 
          
            (refRemoveBillingDetail.current??emptyFunc)(index);  
            
            setTotalAmount(); 
          } else if(getValues().status === '10') {
            setOpenBillingDetailServiceDelivery(true);
          }                      
        }
      }
      return res;
  }

  
  
  
  

  const setTotalAmount = () => {
    const sumNetAmount = sum( getValues().billingDetails.map( ({netAmount}) => netAmount ) );
    const sumTaxAmount = sum( getValues().billingDetails.map( ({tax}) => tax ) );
    const sumAmount = sum( getValues().billingDetails.map( ({amount}) => amount ) );

    setValue('netAmount', sumNetAmount);
    setValue('tax', sumTaxAmount);
    setValue('amount', sumAmount);
  }

    
  const debouncedCustomerChange = useRef(
    debounce( async () => {      
      const { customerId, customerFirstName, customerLastName, customerCorporationName, customerPersonType } = getValues();

      if(customerId>0) return;

      const fn = customerPersonType === 'physical' ? customerFirstName: customerCorporationName;
      const ln = customerPersonType === 'physical' ? customerLastName: customerCorporationName;
      
      if( (isFalsy(fn) || fn === '') && (isFalsy(ln) || ln === '' )) return;

      const count = await getPersonsSearchCount(fn, ln, '');

      if(!isFalsy(refCustomerSnackbarId.current)) closeSnackbar(refCustomerSnackbarId.current!);
      
      if(count > 0) 
        refCustomerSnackbarId.current = enqueueSnackbar( `${t('Identity')} --> ${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
              anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
              action: xActionPersonSearch('customer') } );             

    }, 1500)
  ).current;

  
  const refCustomerSnackbarId = useRef<SnackbarKey>();
  const refDetailBeneficiarySnackbarId = useRef<SnackbarKey>();
  const refPackagingBeneficiarySnackbarId = useRef<SnackbarKey>();

  const xActionPersonSearch = (searchPersonUsage: SearchPersonUsage): SnackbarAction => (snackbarId: SnackbarKey) => (
    <>        
        <Button onClick={() => { 
                // setUrlPdf(`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`); 
                setCurrentSearchPersonUsage(searchPersonUsage);                  
                setOpenPersonSearchFilter(true);
                closeSnackbar(snackbarId); }}>   
            <GrSearch size={24} />
        </Button>
        <Button onClick={() => { closeSnackbar(snackbarId) }}>
            <GrClose size={24} />
        </Button>
    </>
)

  useEffect( () => {     
    setCurrentSearchPersonUsage('customer');         
    debouncedCustomerChange();      
  }, [watchCustomerFirstName, watchCustomerLastName, watchCustomerCorporationName ,debouncedCustomerChange]);

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems]);


  
  useEffect( () => {   
    refProductIndex.current = productIndex;
  
  }, [productIndex]);

    
  useEffect( () => {        
    setCurrentFormNameAtom(t('Billing'));
    setCurrentBasicTextFilterProps(basicFilterBilling);
  }, []);    

  /********** This use effect call retreive data wich will call refetch and _data will be updated. 
    and the new useEffect will take place ********************/
  useEffect( () => {
      // setCurrentFormName(t('Billing'));        
      setCurrentFormNameAtom(_id>0?`${t('Billing')} - # ${_id} # -`: t('Billing') );

      if(_id > 0 && getValues().id !== _id) 
        retrieveData('Billing',_id, refetch);  
    }, [_id] );

  useEffect( () => {
      
    if(_data && _data.id > 0) {      
      reset(_data);
      if(_data.billingDetails.length > 0) billingDetailSelected(0);
    }
  }, [_data, reset]);
  
    const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
      _setId(0);      
      reset(defaultBilling);    
    }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        setTotalAmount();

        if(!checkEntitySaveAuthorization('Billing', _id)) {
          setIsSaveLoading(false);
          return;
        }
        const data = getValues(); 
        
        if( (data.amount <= 0 || data.billingDetails.length <= 0) ) {
          setIsSaveLoading(false);
          return;
        }

        if( (data.customerPersonType === 'physical' && data.customerFirstName.trim() === '' && data.customerLastName.trim() === '') ) {
          enqueueSnackbar( t('The customer is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        if( (data.customerPersonType === 'moral' && data.customerCorporationName.trim() === '' ) ) {
          enqueueSnackbar( t('The customer is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }
                

        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Billing', _id);
      }

      const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
        openEntityPrintDrawer('Billing', _id);
      }

     
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
      queryClient.invalidateQueries(['Billing',_id]);        
      await retrieveData('Billing',_id, refetch);        
      reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                  <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'  >
                          <Box sx={{ mt: 0.25, width: '100%' }} > 
                            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                  sx={{...typographyGroupBoxStyling}}>
                              {`${t(('Identification'))} `}
                            </Typography>                                                       
                          </Box>
                                                                                                                                 
                          <Box sx={{ mt: 1, width: '100%' }} key={` person ${getValues().customerId}`}>
                            <Controller name='customerPersonType' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="customerPersonType"
                                    label={t('Person type')} inputProps={ {readOnly: false}} focused >
                                      <MenuItem value="physical">{t('Physical')}</MenuItem>
                                      <MenuItem value="moral">{t('Moral')}</MenuItem>
                                  </TextField>
                                )}
                              />
                            { (watchCustomerPersonType === 'physical') && <TextField sx={{width:'calc(30% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                              {...register(lg.startsWith('fr')?'customerLastName':'customerFirstName')} inputProps={ {readOnly: (watchCustomerId>0) }} 
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">  
                                  { (isFalsy(watchCustomerId) ||  watchCustomerId<=0) ?
                                      <IconButton color="primary" onClick={(event: any) => handleClickOpenPerson(event, 'customer')}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton> : 
                                      <IconButton color="primary" onClick={(event: any) => handleClickRemovePerson(event, 'customer')}>
                                        <RemoveCircleIcon />
                                      </IconButton>
                                    }                                                                                                                                     
                                </InputAdornment>
                              ) 
                            }} /> }  
                            { (watchCustomerPersonType === 'physical') && <TextField sx={{width:'calc(25% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                              {...register(lg.startsWith('fr')?'customerFirstName':'customerLastName')} inputProps={ {readOnly: (watchCustomerId>0) }} /> } 
                            { (watchCustomerPersonType === 'moral') && <TextField sx={{width:'calc(40% - 8px)'}} id="ln" label={t('Name')} 
                              {...register('customerCorporationName')} inputProps={ {readOnly: (watchCustomerId>0) }}
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">  
                                    { (isFalsy(watchCustomerId) ||  watchCustomerId<=0) ?
                                        <IconButton color="primary" onClick={(event: any) => handleClickOpenPerson(event, 'customer')}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton> : 
                                        <IconButton color="primary" onClick={(event: any) => handleClickRemovePerson(event, 'customer')}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      }                                                                                                                                     
                                  </InputAdornment>
                                ) 
                              }} /> }
                            { (watchCustomerPersonType === 'moral') && <Controller name='customerCorporationType' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="customerCorporationType"
                                    label={t('Type')} inputProps={ {readOnly: false}} focused >
                                    {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_CORPORATION_TYPE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                  </TextField>
                                )} />  }  
                            <Controller name={watchCustomerPersonType === 'physical'? 'customerBirthDateType': 'customerCreationDateType'} control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(12% - 8px)'}} id="customerBirthDateType"
                                    label={t('Date type')} inputProps={ {readOnly: false}} focused >
                                    {getBirthDateType().map( 
                                      (x,idx) => <MenuItem key={x.value} value={x.value}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                              />   
                            {(watchCustomerPersonType === 'physical') && <Controller key={`${watchCustomerBirthDateType} dob`} control={control}
                                name={'customerBirthDate'} 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Birth date')} 
                                    onChange={onChange}                
                                    inputFormat={watchCustomerBirthDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                    value={watchCustomerBirthDateType==='none'?null:value}
                                    views={watchCustomerBirthDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                    disabled={watchCustomerBirthDateType==='none'}
                                    //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    renderInput={(params) => <TextField {...params}  sx={{width:'calc(18% - 8px)'}} />}
                                  /> )}
                              />  }        
                              {(watchCustomerPersonType === 'moral') && <Controller key={`${watchCustomerCreationDateType} dob`} control={control}
                                name={'customerCreationDate'} 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Creation date')} 
                                    onChange={onChange}                
                                    inputFormat={watchCustomerCreationDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                    value={watchCustomerCreationDateType==='none'?null:value}
                                    views={watchCustomerCreationDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                    disabled={watchCustomerCreationDateType==='none'}
                                    //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    renderInput={(params) => <TextField {...params}  sx={{width:'calc(18% - 8px)'}} />}
                                  /> )}
                              />  }                  
                              { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                                okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {setOpenPersonFilter(false);}}  >
                                    <BasicTextFilterForm<IPerson> {...basicFilterPerson } />
                            </FormDialog> }
                                                        
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} > 
                            <TextField sx={{width:'calc(15% - 8px)'}} id="customerPortable1" label={`${t('Portable')} 1`} 
                              {...register('customerPortable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                            <TextField sx={{width:'calc(15% - 8px)'}} id="customerPortable2" label={`${t('Portable')} 2`} 
                              {...register('customerPortable2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>   
                            <TextField sx={{width:'calc(35% - 8px)'}} id="customerEmail1" label={`${t('Email')} 1`} 
                              {...register('customerEmail1')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                            <TextField sx={{width:'calc(35% - 8px)'}} id="customerEmail2" label={`${t('Email')} 2`} 
                              {...register('customerEmail2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>                                                    

                            { openPersonSearchFilter && <FormDialog open={openPersonSearchFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonSearchFilter(false);}} onOk={()=> {setOpenPersonSearchFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPersonSearch } />
                              </FormDialog> } 
                          </Box>                          
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'  >
                          <Box sx={{ mt: 0.25, width: '100%' }} > 
                            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                  sx={{...typographyGroupBoxStyling}}>
                              {`:: ${t(('Total'))} ::`}
                            </Typography>                                                       
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                              

                              <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />
                              
                              <TextField sx={{width:'calc(40% - 8px)'}} id="reference" label={t('Reference')} {...register('reference')} inputProps={ {readOnly: false}} /> 
                              
                              <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Net')} sx={{width:'calc(12% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`netAmount`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Tax')} sx={{width:'calc(12% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`tax`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Amount')} sx={{width:'calc(12% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`amount`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Paid')} sx={{width:'calc(12% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`amountPaid`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Unpaid')} sx={{width:'calc(12% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`unpaidAmount`}
                                control={control}
                            />
                          </Box>        
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <Controller control={control}
                                name='issueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Issue date')} 
                                    onChange={onChange} disableOpenPicker readOnly                   
                                    value={value}
                                    renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                  /> )}
                              />
                            <Controller name='status' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="status"
                                      label={t('Status')} inputProps={ {readOnly: true}} >
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_BILLING_STATUS ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                            <Controller control={control}
                              name='statusDate' 
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DatePicker label={t('Status date')} 
                                  onChange={onChange} disableOpenPicker readOnly                    
                                  value={value}
                                  renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                /> )}
                            />
                            <Controller name='statusPurpose' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(45% - 8px)'}} id="status"
                                      label={t('Status purpose')} inputProps={ {readOnly: true}} >
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_BILLING_STATUS_PURPOSE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                          </Box>
                          
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={`${'watchFileName'} - key`} sx={{ mt: 0.25, width: '100%' }} >  
                          <ArrayFieldTableEx<IBilling,IBillingDetail,'id'> 
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headBillingDetailCells} rowsPathName='billingDetails' 
                              title={t('Add product(s)')} rowActionIcon={billingDetailRowActionIcon}  
                              onRowSelected={handleBillingDetailSelected}
                                                  
                              refAppend={refAppendBillingDetails as MutableRefObject<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateBillingDetail as MutableRefObject<(index: number,value: Partial<FieldArray<IBilling>>) => void>}
                              refRemove={refRemoveBillingDetail as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={
                                (getValues().status === '95') ?
                                [
                                  { toolTip: `${t('Add')}... ${t('Product')}`, onClickIcon: handleAddProducts ,icon: AddCircleIcon, isThere: true },
                                  
                                ].filter(x => x.isThere).map( x => x) : []
                            } 
                              canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                          />
                          { openProductFilter && <FormDialog open={openProductFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Service')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenProductFilter(false);}} onOk={()=> {setOpenProductFilter(false);}}  >
                                      <BasicTextFilterForm<IProduct> {...basicFilterService } />
                              </FormDialog> }
                         
                          { openEmployeeFilter && <FormDialog open={openEmployeeFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Employee')} onCancel={()=> {}} 
                              onClose={()=> {setOpenEmployeeFilter(false);}} onOk={()=> {setOpenEmployeeFilter(false);}}  >
                                  <BasicTextFilterForm<IEmployee> {...basicFilterEmployee } />
                          </FormDialog> }
                          
                        </Box>
                        <Box sx={{ mt: 2.25, width: '100%' }} > 
                          <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                sx={{...typographyGroupBoxStyling}}>
                            {`${t('Employee')} ... ${t('Number')}`}
                          </Typography>                                                       
                        </Box> 
                        <Box sx={{ mt: 0.25, width: '100%' }} key={` employee ${getValues().businessEmployeeId}`}> 
                          <TextField sx={{width:'calc(25% - 8px)'}} id="businessEmployeeFullName" label={t('Employee')} 
                              {...register('businessEmployeeFullName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">  
                                  { (isFalsy(watchBusinessEmployeeId) ||  watchBusinessEmployeeId<=0) ?
                                      <IconButton color="primary" onClick={(event: any) => handleClickSelectEmployee(event, 'business')}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton> : 
                                      <IconButton color="primary" onClick={(event: any) => handleClickResetEmployee(event, 'business')}>
                                        <RemoveCircleIcon />
                                      </IconButton>
                                    }                                                                                                                                     
                                </InputAdornment>
                              ) 
                            }} />        
                            <TextField sx={{width:'calc(25% - 8px)'}} id="businessEmployeeManagerFullName" label={t('Manager')} 
                              {...register('businessEmployeeManagerFullName')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } }
                            />                                              
                        </Box>
                        
                                                                        
                      </Stack>
                    </Grid>     
                                              
                </Grid>
            </Box>
        </FormProvider> 
  )
}

