
export type BillingDetailType = 'credit' | 'appointment' | 'service';

export interface ISysBilling {
    id: number,

    status: string,
    statusDate: Date,
    statusPurpose: string,

    netAmount: number,
    tax: number,
    amount: number,

    amountPaid: number,

    issueDate: Date,
    reference: string,
    
    sysBillingDetails: ISysBillingDetail[],
    
  }

export interface ISysBillingDetail {
  id: number,
  baseAmount: number,
  discount: number,
  loaded: number,
  netAmount: number,
  tax: number,
  amount: number,
  effectiveDate: Date,
  durationInMonth: number,

  unityPrice: number,
  quantity: number,
  contractId: number,

  type: BillingDetailType
}

export const defaultSysBillingDetail : ISysBillingDetail = {
  id: 0,
  baseAmount: 0,
  discount: 0,
  loaded: 0,
  netAmount: 0,
  tax: 0,
  amount: 0,
  effectiveDate: new Date(),
  durationInMonth: 0,

  unityPrice: 0,
  quantity: 0,
  contractId: 0,

  type: 'credit'
}
  
  export const defaultSysBilling : ISysBilling = {
    
    id: 0,
    status: '95',
    statusDate: new Date(),
    statusPurpose: '',

    netAmount: 0,
    tax: 0,
    amount: 0,

    amountPaid: 0,

    issueDate: new Date(),
    reference: '',
    
    sysBillingDetails: [{...defaultSysBillingDetail, type: 'credit' }, 
          {...defaultSysBillingDetail, type: 'appointment', effectiveDate: new Date(), durationInMonth: 0},
          {...defaultSysBillingDetail, type: 'service', effectiveDate: new Date(), durationInMonth: 0}]
  }

  

 export interface IDashboardSysBilling {
 
     
  }  

  export interface ISysBillingSearch {
 
    startDate: Date,
    endDate: Date,
    
    reference: string    
  }