
export interface IMessage {
    id: number,
    
    contractId: number,
    templateMessageId: number,
    reportId: number,

    isApiMessage: boolean,

    messageCampaign:string,
    messageReference:string,
    groupRecipientId: number,

    messageHeader: string,
    recipientNumber: string,
    messageBody: string,

    effectiveDate: Date,
    expirationDate: Date,

    status: string,
    statusDate: Date,
    statusPurpose: string,
        
    isSended: boolean,
    countCredit: number,
    sendingDate: Date,
    deliveryStatus: string,
    
    countConsecutiveFailures: number,
    lastErrorMessage: string,   

    reportName: string,
    templateMessageDescription: string,
    
    recipientNumbers: string[],

    currentRecipient: string
  }

  
  

  export interface IMessageDashboardDay {
    countMessageCreated: number,
    countMessageSended: number,
    creditMessageSended: number,

    countMessageFailled: number,
    countPendingMessage: number,

    barChatLabels: string[],
    countMessagesSended: number[],
    countMessagesCreated: number[],

    countCreditsByOperator: {label: string, value: number}[]
  }

  
  export const defaultMessage : IMessage = {

    id: 0,
    
    contractId: 0,
    templateMessageId: 0,
    reportId: 0,

    isApiMessage: false,

    messageCampaign: '',
    messageReference: '',
    groupRecipientId: 0,

    messageHeader: '',
    recipientNumber: '',
    messageBody: '',

    effectiveDate: new Date(),
    expirationDate: new Date(),

    status: '10',
    statusDate: new Date(),
    statusPurpose: '',
        
    isSended: false,
    countCredit: 0,
    sendingDate: new Date(),
    deliveryStatus: '',
    
    countConsecutiveFailures: 0,
    lastErrorMessage: '',  

    reportName: '',
    templateMessageDescription: '',

    recipientNumbers: [],

    currentRecipient: '',
    
  }

  export const defaultMessageDashboardDay : IMessageDashboardDay = {
    countMessageCreated: 0,
    countMessageSended: 0,
    creditMessageSended: 0,

    countMessageFailled: 0,
    countPendingMessage: 0,

    barChatLabels: [],
    countMessagesSended: [],
    countMessagesCreated: [],

    countCreditsByOperator: []
  }

  export interface IMessageSearch {

    startDate: Date,
    endDate: Date,

    messageCampaign: string,
    messageBody: string,
    recipientNumber: string
  }

  