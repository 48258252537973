
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TokenIcon from '@mui/icons-material/Token';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { IContract, defaultContract } from './models/Contract';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import useMessageService from './services/Message';


import { currentBasicTextFilterPropsAtom, currentFormNameAtom, 
  isSaveLoadingAtom, isSearchBoxShowAtom, currentUserSessionAtom } from 'library/store';
import useContractService, { useBasicFilterContract } from './services/Contract';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

import useEnumerationService from 'features/configuration/services/Enumeration';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration, { Enum_CONTRACT_COUNTRY, Enum_CONTRACT_STATUS_PURPOSE, Enum_CONTRACT_STATUS,
   IEnumerationItem } from 'features/configuration/models/Enumeration';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';

import md5 from 'md5';
import { globalConfig } from 'config';
import { isFalsy } from 'utility-types';
import { typographyGroupBoxStyling } from 'themes/commonStyles';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { ThreeCircles } from 'react-loader-spinner';
import { green } from '@mui/material/colors';
import { SiMicrosoftexcel } from 'react-icons/si';

import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';

const rand = () => {
  return Math.random().toString(36).substring(2);
};

const generateToken = (len: number) : string => {
  return (rand() + rand() + rand() + rand()).substring(0,len);
};

export const ContractForm: FC<IContract> = (props: IContract = {...defaultContract, token: generateToken(26)} ) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();

  const theme = useTheme();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const { createContract, updateContract, getContractStatus, getContractActivation } = useContractService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const {generateContractMessageXlsx} = useMessageService();

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const basicFilterContract = useBasicFilterContract( 
    (event: React.MouseEvent<unknown>, row: IContract) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );


  const [isExportingMessages, setIsExportingMessages] = useState<boolean>(false);

  const emptyFunc = (obj: any) => {}

  const methods = useForm<IContract>({defaultValues:{...defaultContract, token: generateToken(26)}});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;
  
  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IContract>,Error,IContract>(
      _id>0?updateContract:createContract, {   
        onSuccess: (data: IResult<IContract>) => {
          enqueueSnackbar( '_Operation_done', { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Contract')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Contract',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
               anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IContract>(['Contract', _id], () => retrieveEntity('Contract',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Contract'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_CONTRACT_COUNTRY, Enum_CONTRACT_STATUS_PURPOSE, Enum_CONTRACT_STATUS ] ));

     const getInputAdornmentToken = (row: IContract, cellId: keyof IContract)  => ({
 
      toolTip: 'Token gen....',
      icon: TokenIcon,
      onClickIcon: (event: any, index: number, row: IContract ) => {
        // const contract = getValues().contracts[index];    

        // (refUpdateContract.current??emptyFunc)(index, {...contract, identityToken: generateToken(26)});
      }  
   })

      const cellEditable = (row: IContract, cellId: keyof IContract) => {
        return true;
     }
     
    const handleClickGenerateIdentityToken = (event: any) => {
      setValue('token', generateToken(26));
    }
    
    const [base64QrCode, setBase64QrCode] = useState<string>('');

    const handleAddContracts = (event: any) => {
      // const minId = Math.min( ...getValues().contracts.map( ctr => ctr.id) ) - 1;
      // (refAppendContracts.current??emptyFunc)({ customerId: _data?.id ?? 0,
      //   id: 0, identityToken: generateToken(26), login: '', description: '', isPasswordChanged: true, password: '', passwordMD5: '',
      //   instanceId: '', token: '',  status: '', statusDate: new Date(), 
      //    });
    } 

    const [openInnerContractActivation, setOpenInnerContractActivation] = useState(false);
    const handleClickActiveInnerContract = async (event: any) => {
      const imgBase64 = await getContractActivation(_id);

      setBase64QrCode(imgBase64);
      setOpenInnerContractActivation(true);
    }
    
    const handleTogglePasswordVisibility = (event: any) => {

      setIsPasswordVisible( !isPasswordVisible);
    }

    
      
      useEffect( () => {        
        setCurrentFormNameAtom(t('Contract'));
        setCurrentBasicTextFilterProps(basicFilterContract);

        // const {id} = currentUserSession;
        // _setId(id);
      }, []);    
      
      

      // useEffect( () => {        
        
      //   async function _() {
      //     if(_id > 0) {
      //       const {phoneNumber, profilePicture} = await getContractPhoneInformation(_id);

      //       console.log({phoneNumber, profilePicture});
      //       setValue('whatsAppNumber', phoneNumber);
      //       setValue('profilePicture', profilePicture);
      //     }          
      //   }

      //   _();
      // }, [_id]);

    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Contract')} - # ${_id} # -`: `${t('Contract')}` );
            if(_id > 0)
              retrieveData('Contract',_id, refetch);  
          }, [_id] );

              
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
          
            reset(_data);
        }
        }, [_data]);
    
           
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultContract, token: generateToken(26)});    
      }

      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
          
          if(!checkEntitySaveAuthorization('Contract', _id)) {
            setIsSaveLoading(false);
            return;
          }

          const data = getValues(); 
          // if(data.fullName.trim() === '' ) {
          //     enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
          //           anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          //     return;
          //   }
                
          // data.contracts.forEach( ctr => {
          //     ctr.passwordMD5 = md5(ctr.password); 
          //     ctr.isPasswordChanged= (ctr.password != '********'); 
          //   } );
          
          mutate( {...data});
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Contract', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          <Box display="flex" justifyContent="center" flexWrap="wrap" gap={1} sx={{ mt: 1, width: '100%' }} flexDirection='row' >
                            <WidgetSummaryBasic title={`- ${t('Current credits')}`} paletteColor={theme.palette['success']} 
                              iconName='IoSendSharp' total={getValues().currentCredit} />
                            <WidgetSummaryBasic title={`- ${t('Purchased credits')} `} paletteColor={theme.palette['info']} 
                              iconName='IoSendSharp' total={getValues().currentCredit}  />
                            <WidgetSummaryBasic title={`- ${t('Credits used')} `} paletteColor={theme.palette['error']} 
                              iconName='IoSendSharp' total={getValues().currentCredit}  />
                            <WidgetSummaryBasic title={`- ${t('Authorized debit')} `} paletteColor={theme.palette['warning']} 
                              iconName='IoSendSharp' total={getValues().currentCredit} />
                          </Box>
                          
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                      
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                              
                              <TextField sx={{width:'calc(40% - 8px)'}} id="defaultHeader" label={t('Default header')} 
                                  {...register('defaultHeader')} inputProps={ { maxLength: 11, autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } }
                                  helperText={`${t('Name view by recipient')} (11)`} />
                              <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                label={t('Is dynamic ?')}
                                control={
                                <Controller
                                    name='isDynamicHeader'
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />  
                              <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                label={t('Active ?')}
                                control={
                                <Controller
                                    name='isActive'
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />  
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <Controller 
                                name={`defaultCountryCode`}
                                control={control}
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="status"
                                    label={t('Default country')} inputProps={ {readOnly: false}}>                                
                                    {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_CONTRACT_COUNTRY ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                  </TextField>
                                )}
                              />
                              <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                label={t('Send out of country ?')}
                                control={
                                <Controller
                                    name='canSendOutOfCountry'
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat    
                                        label={`${t('Phone number length')}`} sx={{width:'calc(30% - 8px)'}} //disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name={`defaultPhoneNumberLength`}
                                  control={control}
                              /> 
                              
                          </Box>                            
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} 
                                  {...register('description')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } } />                                
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <TextField sx={{width:'calc(40% - 8px)'}} id="description" label={t('Contract Id')} 
                                  {...register('contractId')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: true } }  />  
                            <TextField sx={{width:'calc(60% - 8px)'}} id="identityToken" label={t('Identity token')} 
                              {...register('token')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: true } } 
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">                                            
                                      <IconButton color="primary" onClick={handleClickGenerateIdentityToken}>
                                        <TokenIcon />
                                      </IconButton>                                                                                               
                                  </InputAdornment>
                                )
                              }} />
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <Controller 
                                name={`status`}
                                control={control}
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="status"
                                    label={t('Status')} inputProps={ {readOnly: true}}>                                
                                    {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_CONTRACT_STATUS ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                  </TextField>
                                )}
                            /> 
                            <Controller control={control}
                                name='statusDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Date')} 
                                    onChange={onChange}  readOnly disableOpenPicker                   
                                    value={value}
                                    renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                                  /> )}
                              />                                
                             <Controller 
                                name={`status`}
                                control={control}
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                    label={t('Status purpose')} inputProps={ {readOnly: true}}>                                
                                    {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_CONTRACT_STATUS_PURPOSE ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                  </TextField>
                                )}
                            /> 
                          </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(100% - 8px)'}} id="webhookUrl" label={t('Webhook URL')} 
                                    {...register('webhookUrl')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={5} component={Paper} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={`${getValues().contractId}`} >
                          
                        </Box>           
                      </Stack>   
                    </Grid>                   
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

