

export interface IContract {
    id: number,
    isActive: boolean,
    description: string,

    defaultHeader: string,
    isDynamicHeader: boolean,

    currentCredit: number,
    
    token: string,
    status: string,
    statusDate: Date,
    statusPurpose: string,
              
    webhookUrl: string,

    defaultCountryCode: string,
    defaultPhoneNumberLength: number,
    canSendOutOfCountry: boolean,

    maxDebitAllowed: number,

    countConsecutiveFailures: number,    

    contractId: string
  }

  export const defaultContract : IContract = {
    id: 0,
    
    isActive: true,
    description: '',

    defaultHeader: '',
    isDynamicHeader: false,

    currentCredit: 0,
    
    token: '',
    status: '95',
    statusDate: new Date(),
    statusPurpose: '',
              
    webhookUrl: '',

    defaultCountryCode: '',
    defaultPhoneNumberLength: 15,
    canSendOutOfCountry: true,

    maxDebitAllowed: 0,

    countConsecutiveFailures: 0,    

    contractId: ''
  }

  

  export interface IContractSearch {
  
    description: string,
    defaultHeader: string
  }