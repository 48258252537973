
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IProduct, IProductSearch, IServiceTask } from "../models/Product";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
//import { IPolicyRisk, IPolicyRiskCoverage } from 'features/production/models/Policy';


const _ = () => {

    const axios = useAxios(); 

    const createProduct = async (product: IProduct)  =>       
        await (await axios.post('/api/setup/product/create', product)).data;       
        
    const updateProduct = async (product: IProduct)  =>       
        await (await axios.post('/api/setup/product/update', product)).data; 
    
    const getProduct = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/product/get-product/${id}`));
      return await data;
    }
   

    const getProducts = async (type: number, criteria: IProductSearch, pagination?: IPagination) : Promise<IProduct[]> => {

      const {name, description, lineOfBusinessCode} = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/setup/product/get-products?type=${type}&name=${name}&description=${description}&lineOfBusinessCode=${lineOfBusinessCode}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getProductsSearchCount = async (name: string, description: string) : Promise<number> => {
      
      const {data} = (await axios.get(`/api/setup/product/get-products-search-count?name=${name}&description=${description}`));
      return await data;
    }

    const getProductsSearch = async (name: string, description: string, criteria: IProductSearch, pagination?: IPagination) : Promise<IProduct[]> => {

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/setup/product/get-products-search?name=${name}&description=${description}`+
                  `&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getServiceTasksByService = async (serviceId: number) : Promise<IServiceTask[]> => {

      const {data} = (await axios.get(`/api/setup/product/get-serviceTasks-by-service?serviceId=${serviceId}`));
      return await data;
    }
    
    const getServiceTasksByPackaging = async (packagingId: number) : Promise<IServiceTask[]> => {

      const {data} = (await axios.get(`/api/setup/product/get-serviceTasks-by-packaging?packagingId=${packagingId}`));
      return await data;
    }

    
   
    return {    
      createProduct,
      updateProduct,
      getProduct,
      getProducts,

      getProductsSearchCount,
      getProductsSearch,

      getServiceTasksByService,
      getServiceTasksByPackaging
    } 
}

export default _;

export interface IFilterProductOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IProduct[], React.Dispatch<React.SetStateAction<IProduct[]>>],
}

const defaultFilterProductOption: IFilterProductOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterProduct = ( type: number,  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IProduct) => void,
                                            filterOption?: IFilterProductOption  ) => {

  const { getProducts } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;



  const [headProductCells, setHeadProductCells]  = useState<HeadCell<IProduct>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', },
    {id:'lineOfBusinessName', label : t('Line of business'),  display: true, type: 'string', },

    // {id: 'quantityAvailable', label : `${t('Quantity')} : ${t('Available')}`,  display: true && (type === 2), type: 'numeric',},
    // {id: 'quantityStock', label : `${t('Quantity')} : ${t('Stock')}`,  display: true && (type === 2), type: 'numeric',}
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      {name: 'lineOfBusinessCode', text: t('Line of business'), value: ''},
    ]);    

  const [filteredProducts, ] = useState<IProduct[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IProduct[]> => {
   
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const lineOfBusinessCode = filterElements.find( elt => elt.name === 'lineOfBusinessCode')?.value || '';

    const arr = await getProducts(type, { name,description, lineOfBusinessCode}, pagination );
    
    return arr;
  }

  const objKey: keyof IProduct = 'id';

  return {
    title: t('Product'), headCells: headProductCells, objKey,
    filterElements, rows: filteredProducts, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}


export const useBasicFilterProductSearch = ( getSearch: () => {name: string, description: string}, 
                                            onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IProduct) => void,
                                            filterOption?: IFilterProductOption  ) => {

  const { getProductsSearch } = _();

  const { t, i18n } = useTranslation();   
  const {language: lg, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;

  const [headProductCells, setHeadProductCells]  = useState<HeadCell<IProduct>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', },
    {id:'lineOfBusinessName', label : t('Line of business'),  display: true, type: 'string', },
  ]); 

  const {name, description} = getSearch();

  const [filterElements,] = useState<ITextFilterElement[]>( [         
      
    {name: 'portable', text: t('Portable'), value: ''},
    {name: 'email', text: t('Email'), value: ''},
      
  ]);    

  const [filteredProducts, ] = useState<IProduct[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IProduct[]> => {    
      
    const lineOfBusinessCode = filterElements.find( elt => elt.name === 'lineOfBusinessCode')?.value || '';     

    const arr = await getProductsSearch(name, description, { name: '', description: '', lineOfBusinessCode}, pagination );
   
    return arr;
  }

  const objKey: keyof IProduct = 'id';

  return {
    title: `${t('Product')} : ${name} | ${description} `, headCells: headProductCells, objKey,
    filterElements, rows: filteredProducts, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered,
    autoFilter: true
  }
}

