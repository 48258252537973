
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

//import colorNameList from 'color-name-list';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import BackpackIcon from '@mui/icons-material/Backpack';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

import { ChromePicker, ColorResult } from 'react-color';



import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom } from 'library/store';
import useSysBillingService, { useBasicFilterSysBilling } from './services/SysBilling';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, Popover, useTheme } from '@mui/material';
import NumberFormat from 'react-number-format';

import { ISysBilling, ISysBillingDetail, defaultSysBilling } from './models/SysBilling';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

//import useDeliveryService from 'features/configuration/services/Delivery';

import useUtils from 'library/utils';

import IEnumeration, { Enum_LINE_OF_BUSINESS, Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, 
      Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ,IEnumerationItem, Enum_ARTICLE_WRAPPING, Enum_BILLING_STATUS, Enum_BILLING_STATUS_PURPOSE } from 'features/configuration/models/Enumeration';

//import {ISharing} from 'features/setup/models/Sharing';
//import useSharingService, { useBasicFilterSharing } from 'features/setup/services/Sharing';

import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling, carouselImage } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { debounce, sum } from 'lodash';
import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';
import { DatePicker } from '@mui/x-date-pickers';
import { IContract } from 'features/production/models/Contract';
//import { IArticleStock } from 'features/configuration/models/Delivery';

export const SysBillingForm: FC<ISysBilling> = (props: ISysBilling = defaultSysBilling) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { applicationSetup, userContracts } = useRecoilValue(currentUserSessionAtom);

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  //const {getArticlePerStores } = useDeliveryService();

  const { createSysBilling, updateSysBilling, 
      evaluateAmountSMS, evaluateQuantitySMS, evaluateAmountService, evaluateAmountAppointment } = useSysBillingService();

  const {capitalizeFirstLetter} = useUtils();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const colors = colorNameList.filter(c => c.name.split(' ').length === 1 && c.name.length <= 5).map((color) => color);
  
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterSysBilling = useBasicFilterSysBilling( 
    (event: React.MouseEvent<unknown>, row: ISysBilling) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}

  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );


  // const [openSharingFilter, setOpenSharingFilter] = useState(false);
  // const basicFilterSharing = useBasicFilterSharing( 
  //     (event: React.MouseEvent<unknown>, row: ISharing) => {
  //         const {id, name, description} = row;

  //         setValue('sharingId', id);
  //         setValue('sharingName', name);
                           
  //         setOpenSharingFilter(false);
  //     }
  // );
  

  const methods = useForm<ISysBilling>({defaultValues: {...defaultSysBilling }});

  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;
     

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ISysBilling>,Error,ISysBilling>(
      _id>0?updateSysBilling:createSysBilling, {   
        onSuccess: (data: IResult<ISysBilling>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Billing')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['SysBilling',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<ISysBilling>(['SysBilling', _id], () => retrieveEntity('SysBilling',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>(
        ['EnumerationItems', 'SysBilling'], () => getEnumerationItemsByEnumerationCodes( [Enum_BILLING_STATUS, Enum_BILLING_STATUS_PURPOSE ] ));

    const billingDurations = [ {duration: 0, label: ''}, {duration: 1, label: ` 1 ${t('Month')}`},
                      {duration: 2, label: ` 2 ${t('Months')}`}, {duration: 3, label: ` 3 ${t('Months')}`},
                      {duration: 6, label: ` 6 ${t('Months')}`}, {duration: 12, label: ` 12 ${t('Months')}`}]

  useEffect(() => {

    const subscription = watch( async (value, { name, type }) => {
      
      if( name?.startsWith('sysBillingDetails') && name?.endsWith('quantity')  ) {          
        debouncedQuantityChange();                          
      } else if( name?.startsWith('sysBillingDetails.1.durationInMonth') ) {
        debouncedAppointmentDurationChange();                
      } else if(name?.startsWith('sysBillingDetails.2.durationInMonth') ) {        
        debouncedServiceDurationChange();  
      }
    });  

    return () => subscription.unsubscribe();
  }, [watch]);
                  
const debouncedQuantityChange = useRef(
  debounce( async () => {      
    const { id, sysBillingDetails } = getValues();

    const billingSMS = sysBillingDetails[0]; 
    if(isFalsy(billingSMS)) return;    

    const {quantity} = billingSMS;
    
    const res = (quantity > 0) ? await evaluateAmountSMS({quantity}) : undefined;

    const unityPrice = isFalsy(res) ? 0 : res.data.unityPrice;
    const baseAmount = isFalsy(res) ? 0 : res.data.baseAmount;
    const tax = isFalsy(res) ? 0 : res.data.tax;

    const amount = baseAmount + tax;

    setValue(`sysBillingDetails.0.unityPrice`, unityPrice);
    setValue(`sysBillingDetails.0.baseAmount`, baseAmount);
    setValue(`sysBillingDetails.0.discount`, 0);
    setValue(`sysBillingDetails.0.loaded`, 0);
    setValue(`sysBillingDetails.0.netAmount`, baseAmount);
    setValue(`sysBillingDetails.0.tax`, tax);
    setValue(`sysBillingDetails.0.amount`, amount);

    setTotalAmount();
           

  }, 1500)
).current;

const debouncedAppointmentDurationChange = useRef(
  debounce( async () => {      
    const { id, sysBillingDetails } = getValues();

    const billingAppointment = sysBillingDetails[1];
    if(isFalsy(billingAppointment)) return;

    const {durationInMonth} = billingAppointment;
        
    const res = (durationInMonth > 0) ? await evaluateAmountAppointment({durationInMonth}) : undefined;

    const unityPrice = isFalsy(res) ? 0 : res.data.unityPrice;
    const baseAmount = isFalsy(res) ? 0 : res.data.baseAmount;
    const tax = isFalsy(res) ? 0 : res.data.tax;

    const amount = baseAmount + tax;

    setValue(`sysBillingDetails.1.unityPrice`, unityPrice);
    setValue(`sysBillingDetails.1.baseAmount`, baseAmount);
    setValue(`sysBillingDetails.1.discount`, 0);
    setValue(`sysBillingDetails.1.loaded`, 0);
    setValue(`sysBillingDetails.1.netAmount`, baseAmount);
    setValue(`sysBillingDetails.1.tax`, tax);
    setValue(`sysBillingDetails.1.amount`, amount);

    setTotalAmount();
  }, 1000)
).current;

const debouncedServiceDurationChange = useRef(
  debounce( async () => {      
    const { id, sysBillingDetails } = getValues();

    const billingService = sysBillingDetails[2];
    if(isFalsy(billingService)) return;

    const {durationInMonth} = billingService;
   
    const res = (durationInMonth > 0) ? await evaluateAmountAppointment({durationInMonth}) : undefined;

    const unityPrice = isFalsy(res) ? 0 : res.data.unityPrice;
    const baseAmount = isFalsy(res) ? 0 : res.data.baseAmount;
    const tax = isFalsy(res) ? 0 : res.data.tax;

    const amount = baseAmount + tax;

    setValue(`sysBillingDetails.2.unityPrice`, unityPrice);
    setValue(`sysBillingDetails.2.baseAmount`, baseAmount);
    setValue(`sysBillingDetails.2.discount`, 0);
    setValue(`sysBillingDetails.2.loaded`, 0);
    setValue(`sysBillingDetails.2.netAmount`, baseAmount, {shouldValidate: true});
    setValue(`sysBillingDetails.2.tax`, tax);
    setValue(`sysBillingDetails.2.amount`, amount);

    setTotalAmount();
  }, 1000)
).current;


const setTotalAmount = () => {
  const sumNetAmount = sum( getValues().sysBillingDetails.map( ({netAmount}) => netAmount ) );
  const sumTaxAmount = sum( getValues().sysBillingDetails.map( ({tax}) => tax ) );
  const sumAmount = sum( getValues().sysBillingDetails.map( ({amount}) => amount ) );

  console.log(getValues());

  setValue('netAmount', sumNetAmount);
  setValue('tax', sumTaxAmount);
  setValue('amount', sumAmount);
}



      useEffect( () => {        
        setCurrentFormNameAtom(t('Billing'));
        setCurrentBasicTextFilterProps(basicFilterSysBilling);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Billing')} - # ${_id} # -`: t('Billing') );
            if(_id > 0)
              retrieveData('SysBilling',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
            console.log(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultSysBilling });    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('SysBilling', _id)) {
          setIsSaveLoading(false);
             return;
        }          
  
        const data = getValues(); 

        // if( isFalsy(data.lineOfBusinessCode) || data.lineOfBusinessCode.trim() === '') {
        //   enqueueSnackbar( t('Line of business is not specified'), { variant: 'warning',
        //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        //   setIsSaveLoading(false);
        //   return;
        // }
        
        if(data.reference.trim() === '') {
            enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }
    
        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('SysBilling', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
              <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                  <Stack flexDirection='column'  >
                    <Box sx={{ mt: 1, width: '100%' }} >
                      <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                      <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                      <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                      <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                      
                      <TextField sx={{width:'calc(75% - 8px)'}} id="name" label={t('Reference')} {...register('reference')}
                        inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />   
                      <Controller control={control}
                              name='issueDate' 
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DatePicker label={t('End date')} 
                                  onChange={onChange}                     
                                  value={value}
                                  renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                /> )}
                            />  
                                                                    
                    </Box>  
                    <Box sx={{ mt: 1, width: '100%' }} >
                      <Controller 
                          name={`status`}
                          control={control}
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="status"
                              label={t('Status')} inputProps={ {readOnly: true}}>                                
                              {enumItems && enumItems.filter( e => 
                                          e.enumerationCode === Enum_BILLING_STATUS ).map( 
                                      (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                    }
                            </TextField>
                          )}
                      />
                      <Controller control={control}
                          name='statusDate' 
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <DatePicker label={t('Date')} 
                              onChange={onChange}  readOnly disableOpenPicker                   
                              value={value}
                              renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                            /> )}
                        />   
                      <Controller 
                          name={`statusPurpose`}
                          control={control}
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="status"
                              label={t('Status purpose')} inputProps={ {readOnly: true}}>                                
                              {enumItems && enumItems.filter( e => 
                                          e.enumerationCode === Enum_BILLING_STATUS_PURPOSE ).map( 
                                      (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                    }
                            </TextField>
                          )}
                      />
                    </Box> 
                    <Box sx={{ mt: 1, width: '100%' }} >                    
                      <Controller                                
                        render={({ field }) => {
                          return (
                            <NumberFormat      
                              decimalScale={2}
                              label={t('Net amount')} sx={{width:'calc(25% - 8px)'}} disabled={true}
                              allowEmptyFormatting={false}
                              control={control}
                              //fixedDecimalScale={true} 
                              thousandSeparator={true}
                              //onValueChange={ (v) => onChange(v.floatValue) }
                              {...field}
                              customInput={TextFieldRight}
                              //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                            />
                          );
                        }}
                        name={`netAmount`}
                        control={control}
                      />
                      <Controller                                
                          render={({ field }) => {
                            return (
                              <NumberFormat      
                                decimalScale={2}
                                label={t('Tax')} sx={{width:'calc(25% - 8px)'}} disabled={true}
                                allowEmptyFormatting={false}
                                control={control}
                                //fixedDecimalScale={true} 
                                thousandSeparator={true}
                                {...field}
                                customInput={TextFieldRight}
                                //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                              />
                            );
                          }}
                          name={`tax`}
                          control={control}
                        />
                        <Controller                                
                          render={({ field }) => {
                            return (
                              <NumberFormat      
                                decimalScale={2}
                                label={t('Amount')} sx={{width:'calc(25% - 8px)'}} disabled={true}
                                allowEmptyFormatting={false}
                                control={control}
                                //fixedDecimalScale={true} 
                                thousandSeparator={true}
                                {...field}
                                customInput={TextFieldRight}
                                //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                              />
                            );
                          }}
                          name={`amount`}
                          control={control}
                        />
                        <Controller                                
                          render={({ field }) => {
                            return (
                              <NumberFormat      
                                decimalScale={2}
                                label={t('Amount paid')} sx={{width:'calc(25% - 8px)'}} disabled={true}
                                allowEmptyFormatting={false}
                                control={control}
                                //fixedDecimalScale={true} 
                                thousandSeparator={true}
                                {...field}
                                customInput={TextFieldRight}
                                //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                              />
                            );
                          }}
                          name={`amountPaid`}
                          control={control}
                        />
                    </Box>                            
                  </Stack>                        
                </Grid>
                <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >  
                  <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                    <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                      {`${t('SMS credit')} `}
                    </Typography>          
                  </Box>
                  <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                    <Controller 
                          name={`sysBillingDetails.0.contractId`}
                          control={control}
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="contractId"
                              label={t('Contract')} inputProps={ {readOnly: false}}>                                
                              {userContracts.map( 
                                 (x,idx) => <MenuItem key={x.contractId} value={x.contractId}>{x.contractDescription}</MenuItem> )
                              }
                            </TextField>
                          )}
                      />
                    <Controller                                
                        render={({ field: {onChange, onBlur, name, value, ref} }) => {
                          return (
                            <NumberFormat      
                              
                              decimalScale={2}
                              label={t('Quantity')} sx={{width:'calc(20% - 8px)'}}
                              allowEmptyFormatting={false}
                              control={control}
                              //fixedDecimalScale={true} 
                              thousandSeparator={true}
                              onValueChange={ (v) => onChange(v.floatValue) }
                              defaultValue={value}
                              value={value}
                              //{...field}
                              customInput={TextFieldRight}
                              //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                            />
                          );
                        }}
                        name={`sysBillingDetails.0.quantity`} 

                        control={control}
                      />
                      <Controller                                
                        render={({ field }) => {
                          return (
                            <NumberFormat      
                              decimalScale={2} 
                              label={t('Unity price')} sx={{width:'calc(20% - 8px)'}} disabled={true}
                              allowEmptyFormatting={false}
                              control={control}
                              //fixedDecimalScale={true} 
                              thousandSeparator={true}
                              {...field}
                              customInput={TextFieldRight}
                              //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                            />
                          );
                        }}
                        name={`sysBillingDetails.0.unityPrice`}
                        control={control}
                      />
                      <Controller                                
                        render={({ field }) => {
                          return (
                            <NumberFormat      
                              decimalScale={2}
                              label={t('Net amount')} sx={{width:'calc(25% - 8px)'}} disabled={true}
                              allowEmptyFormatting={false}
                              control={control}
                              //fixedDecimalScale={true} 
                              thousandSeparator={true}
                              {...field}
                              customInput={TextFieldRight}
                              //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                            />
                          );
                        }}
                        name={`sysBillingDetails.0.amount`}
                        control={control}
                      />
                  </Box>
                  <Box sx={{ mt: 2.25, mb: 0.25, width: '100%' }} >
                    <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                      {`${t('Appointment management')} `}
                    </Typography>          
                  </Box>
                  <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                    <Controller control={control}
                          name={`sysBillingDetails.1.effectiveDate`}
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <DatePicker label={t('Date')} 
                              onChange={onChange}                     
                              value={value}
                              renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                            /> )}
                        />
                        <Controller 
                          name={`sysBillingDetails.1.durationInMonth`}
                          control={control}
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="status"
                              label={t('Status purpose')} inputProps={ {readOnly: false}}>                                
                              {billingDurations.map( 
                                      (x,idx) => <MenuItem key={x.duration} value={x.duration}>{x.label}</MenuItem> )
                                    }
                            </TextField>
                          )}
                      /> 
                      <Controller                                
                        render={({ field }) => {
                          return (
                            <NumberFormat      
                              decimalScale={2}
                              label={t('Net amount')} sx={{width:'calc(35% - 8px)'}} disabled={true}
                              allowEmptyFormatting={false}
                              control={control}
                              //fixedDecimalScale={true} 
                              thousandSeparator={true}
                              {...field}
                              customInput={TextFieldRight}
                              //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                            />
                          );
                        }}
                        name={`sysBillingDetails.1.amount`}
                        control={control}
                      />     
                  </Box>
                  <Box sx={{ mt: 2.25, mb: 0.25, width: '100%' }} >
                    <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                      {`${t('Customer service management')} `}
                    </Typography>          
                  </Box>
                  <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                    <Controller control={control}
                          name={`sysBillingDetails.2.effectiveDate`}
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <DatePicker label={t('Date')} 
                              onChange={onChange}                     
                              value={value}
                              renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                            /> )}
                        />
                        <Controller 
                          name={`sysBillingDetails.2.durationInMonth`} 
                          control={control}
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="status"
                              label={t('Status purpose')} inputProps={ {readOnly: false}}>                                
                              {billingDurations.map( 
                                      (x,idx) => <MenuItem key={x.duration} value={x.duration}>{x.label}</MenuItem> )
                                    }
                            </TextField>
                          )}
                        />      
                        <Controller                                
                          render={({ field }) => {
                            return (
                              <NumberFormat      
                                decimalScale={2}
                                label={t('Subscription duration')} sx={{width:'calc(35% - 8px)'}} disabled={true}
                                allowEmptyFormatting={false}
                                control={control}
                                //fixedDecimalScale={true} 
                                thousandSeparator={true}
                                {...field}
                                customInput={TextFieldRight}
                                //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                              />
                            );
                          }}
                          name={`sysBillingDetails.2.amount`}
                          control={control}
                        />
                  </Box>
                </Grid>                         
              </Grid>
            </Box>
        </FormProvider> 
  )
}

